import React from 'react';  
import styles from './styles.module.scss'; 
import { AiOutlineClose } from "react-icons/ai"; 

export const ClosingTag = ({ text, onClick }) => { 
  

    return (
         
              <li className={styles.p16flex}>
                <div className={styles.ult2}>
                <div className={styles.mr8ss}>
                  <span id={styles.rm9022y}></span>
                  <span id={styles.rm9022y}></span>
                  <span id={styles.rm9022y}></span>
                  <span id={styles.rm9022y}></span>
                </div>
             
                <div className={styles.p12in}>
                  <span><p>{text}</p></span>
                </div>
                </div>
                <div className={styles.p06out}>
                <div className={styles.exitButtonOnNavbar}>
            <AiOutlineClose onClick={onClick} className={styles.closingTag}/>
           
          </div>
                </div>
              </li>
              
    )
}
