import likecandyD531 from '../../../images/review1.png';
import likecandyD532 from '../../../images/review2.png';
import likecandyD533 from '../../../images/review3.png';
import likecandyD534 from '../../../images/review4.png';
import likecandyD535 from '../../../images/review5.png'; 
import likecandyD537 from '../../../images/review6.png'; 

export const dataReviews = [
    {
        name: "Rebeca M.",
        review: 'I really liked this model since it is of good quality I recommend it a lot without a doubt I would buy it again', 
        reviewImage: [likecandyD531], 
  
    },
    {
      name: "Olivia",
      review: 'fantastic, incredible, very beautiful. I feel like VS model in it😉', 
      reviewImage: [likecandyD532], 

  }, 
    {
        name: "Eva T.",
        review: 'It is of very good quality, highly recommended. Simply Perfect!!!!', 
        reviewImage: [likecandyD533], 
  
    },
    {
      name: "Ana E.",
      review: `Very sexy feathers, it's very beautiful`, 
      reviewImage: [likecandyD534], 
  
    },
    {
        name: "Veronika",
        review: 'the set is very beautiful 😍 the color is amazing😍',   
        reviewImage: [likecandyD535], 
      },
      {
        name: "Emily P.",
        review: 'corresponds to price and quality',   
      reviewImage: [likecandyD537]
      },
      {
        name: "Isabella S.",
        review: 'A set of a bomb, all super, the delivery is fast and the quality is great!! ',    
      },
      {
        name: "Rosie T.",
        review: 'Very flirtatious lingerie, very pretty.',    
      },
      {
        name: "Anna J.",
        review: 'Very good quality I loved it!',   
      },
      {
        name: "Sophie",
        review: 'Very nice',  
      },
      {
        name: "Helena",
        review: 'looked like a goddess 10000%/10 recommended!',    
      },
      {
        name: "Luna T.",
        review: 'For your money, quality bomb🥰',  
      },
      {
        name: "Maya",
        review: 'as per description',  
      },
      
     
      
    
  ];
  