import React from "react";
import styles from './styles.module.scss';
import './style.css'


const KriorySpecs = ({onclick}) => {

 
  
    return (
        <div className={styles.mojZ} onClick={onclick}>
         <div className={styles.pl428te}>
         <div className={styles.lock7}>
      <span className={styles.glu8en}>
      Specifications
      </span> 
      </div>
         <div className={styles.isa076e} id="faqs">
          <div className={styles.led902}>
         
          <table className={styles.table}> 
            <thead id={styles.tabletr}>Unit of measurements (cm)</thead>
  <tbody>
    
  <tr>
      <th scope="row" data-label="Account">Size</th>
      <th scope="row" data-label="Account">Under bust</th>
      <th scope="row" data-label="Account">Pants Waist</th>
      <th scope="row" data-label="Account">Bra Length</th>
      <th scope="row" data-label="Account">Pants Length</th>
    </tr> 
    <tr>
      <th data-label="Account">S</th>
      <td data-label="Due Date">68-84</td> 
      <td data-label="Due Date">60-84</td> 
      <td data-label="Due Date">20</td> 
      <td data-label="Due Date">22</td> 
    </tr>
    <tr>
      <th scope="row" data-label="Account">M</th>
      <td data-label="Due Date">72-88</td> 
      <td data-label="Due Date">64-88</td> 
      <td data-label="Due Date">21</td> 
      <td data-label="Due Date">23</td> 
    </tr>  
    <tr>
      <th scope="row" data-label="Account">L</th>
      <td data-label="Due Date">76-92</td> 
      <td data-label="Due Date">68-88</td> 
      <td data-label="Due Date">22</td> 
      <td data-label="Due Date">24</td>  
    </tr>  
    <tr>
      <th scope="row" data-label="Account">XL</th>
      <td data-label="Due Date">80-96</td> 
      <td data-label="Due Date">72-92</td> 
      <td data-label="Due Date">23</td> 
      <td data-label="Due Date">25</td>  
    </tr>  
  </tbody>
</table>
          </div>
         
        
          <div className={styles.raw2ty}>
            <div className={styles.war72yt}>
              <span><p>Warranty & Return Policy</p></span>
            </div>
            <div className={styles.nts21t}>
              {''}
            </div>
            <div className={styles.oew21j}>
              <span><p>Items returned to LikeCandy in acceptable condition within 14 days of delivery date will receive a full refund. Read more <a href="http://kriory.sk/vymena-a-reklamacie">here.</a>
                </p></span>
            </div>
          </div>
         </div>
        </div>
        <div className={styles.j9_descriptionAccord}>
        <div class="accordion">
    <h1>FAQ</h1>
    <div class="accordion-item">
        <input type="checkbox" id="accordion1"/>
        <label for="accordion1" class="accordion-item-title"><span class="icon"></span>How to place an order?</label>
        <div class="accordion-item-desc">     1. Select the product.<br/><br/>
     2. Indicate the appropriate size.<br/><br/>
     3. Choose the “Add to cart” option.<br/><br/>
     4. By clicking the “my cart” icon, you will be taken to your shopping cart. You will be transferred to a new tab where you will find information about the products you         have selected. After completing your purchase, select the “Proceed to checkout” option.<br/><br/> 
     6. Choose a delivery and payment method.<br/><br/>
     7. Read the regulations and privacy policy, and then click “Place order”.</div>
    </div>

    <div class="accordion-item">
        <input type="checkbox" id="accordion2"/>
        <label for="accordion2" class="accordion-item-title"><span class="icon"></span>Shipping and Delivery</label>
        <div class="accordion-item-desc">We offer one shipping method with an estimated delivery time of 7 to 10 days once the order has been shipped.
          <br/>Delivery dates are estimates and not guaranteed. We are not responsible for delays due to unforeseen circumstances. </div>
    </div>

    <div class="accordion-item">
        <input type="checkbox" id="accordion5"/>
        <label for="accordion5" class="accordion-item-title"><span class="icon"></span>Refunds</label>
        <div class="accordion-item-desc"><span id={styles.returnPolicy}><p> Due to the intimate nature of our products, we do not accept returns. However, we offer refunds under specific circumstances.
          <br/>  If you receive a damaged or defective product, please contact us within 14 days of receipt with your order number and details of the issue.
          <br/> Refunds will be processed within 14days of verifying the claim. The refunded amount will be credited to the original method of payment.
                </p></span></div>
    </div>


    <div class="accordion-item">
        <input type="checkbox" id="accordion5"/>
        <label for="accordion5" class="accordion-item-title"><span class="icon"></span>How to cancel an order?</label>
        <div class="accordion-item-desc"><span id={styles.returnPolicy}><p>To cancel a placed order, send an email to support@likecandy.com
                </p></span></div>
    </div>
  
</div>
</div>
   </div>
    
    );
  };

export default KriorySpecs;
 