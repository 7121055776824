import React, { useState } from 'react'; 
import { FiSearch } from 'react-icons/fi';
import { Input } from '../Input/Input';
import styles from '../SearchBar/styles.module.scss';

export function SearchBarMobile({clicked, showSearchBar}) {
 
 
  return (
    <div id={styles.cover}>
        <div className={styles.otRUp}>

       
     
        
              <Input clicked={clicked} className={styles.searchInputMobile} classN={styles.bbFormMobile} classM={showSearchBar}/>
          
              </div> 
        </div>
     
   
  );
}
  
