import React, { useState, useEffect, useContext } from 'react';   
import { Hamburger } from './components/Hamburger/Hamburger';
import { Links } from './components/Links/Links';
import styles from './styles.module.scss';  
import logo from '../../images/likecandylogo.png';
import logoColor from '../../images/logo3.png';
import { SearchBar } from './components/SearchBar/SearchBar';
import { Search } from 'semantic-ui-react';
import login from '../../images/login.svg';
import cart from '../../images/cart.svg';     
 import { NavCarousel } from './components/Carousel/NavCarousel';
import './style.css';
import { CiSearch } from "react-icons/ci";
import { IoCloseOutline } from "react-icons/io5";
import { IoCartOutline } from "react-icons/io5";
import { CartContext } from '../../pages/store/components/CardContext';
import CartProduct from '../../pages/store/components/CartProduct';
import { AiOutlineClose } from "react-icons/ai";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export const Nav = ({state, clicksearch, showSearchBar, searchFun}) => { 
  
  const [isOpened, setIsOpened] = useState(false);
  const [colors, setColors] = useState(false);
  const [show, setShow] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0); 
  const OpenCloseNavbar = () => {
    setIsOpened(false);
    
  };

  
  const controlNavbar = () => {
    if (typeof window !== 'undefined') { 
      if (window.scrollY > lastScrollY || lastScrollY < 35) { 
        setColors(false);
        setShow(false); 
      } else { // if scroll up show the navbar
        setShow(true);  
        setColors(true);
      }   
      // remember current page location to use in the next move
      setLastScrollY(window.scrollY); 
    }
  }; 
  
 
  const controlBody = () => {
    if (isOpened || showCart === true) { 
      document.body.style.setProperty('height', '100vh');
      document.body.style.setProperty('overflow-y', 'hidden');
      
      } else { 
        document.body.style.setProperty('height', 'inherit');
        document.body.style.setProperty('overflow-y', 'inherit'); 
      } 
  }; 


  useEffect(() => {
    controlBody()
}, [controlBody])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY]);


  
  const cart = useContext(CartContext); 
  const [ showCart, setShowCart] = useState(false);
  const [ cartItems, setCartItems] = useState(); 
  const handleClose = () => setShowCart(false);
  const handleShow = () => setShowCart(true); 

 useEffect(() => {
  window.localStorage.setItem('MY_CART', JSON.stringify(cart.items))
 }, [cart.items])

  

  const checkout = async () => {
      await fetch('http://localhost:4000/checkout', {
          method: "POST",
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({items: cart.items})
      }).then((response) => {
          return response.json();
      }).then((response) => {
          if(response.url) {
              window.location.assign(response.url); // Forwarding user to Stripe
          }
      });
  }
 

  const productsCount = cart.items.reduce((sum, product) => sum + product.quantity, 0)

  

 return (
   <header className={styles.ikSeri}> 
   <div  className={show ? styles.navShow : styles.navHidden}>
    <div className={styles.fresnel_container_fresnel_greaterThanOrEqual_lg}>
      <div className={styles.sc1qbsdb4}>
      <Hamburger clicked={() => setIsOpened(!isOpened)}/>
      <Links opened={isOpened ? 1 : 0} onClick={OpenCloseNavbar}/>
        <h1 href="/" className={styles.sc1qgyytsf}>
            <a className={styles.fDDRrD} href="/">
              <span className={styles.q4DSIt}>
                 <img src={logo} className={styles.scbdr4}/> 
               
              </span>
            </a>
        </h1>
        <div className={styles.eTcMXG}>
            <ul className={styles.mxMD}>
              <li>
              <a href="/" className={colors === true ? styles.n2md9color : styles.n2md9}>
                  Home
                 </a>
              </li>
              <li>
              <a href="/products" className={colors === true ? styles.n2md9color : styles.n2md9}>
                  Products
                 </a>
              </li>
              <li>
              <a href="/aboutus" className={colors === true ? styles.n2md9color : styles.n2md9}>
                  About us
                 </a>
              </li>
            </ul>
        </div>
        <ul className={styles.hhQMko}>
        <SearchBar showSearchBar={showSearchBar}/>
            <li className={colors === true ? styles.BpRctacolor : styles.BpRcta} onClick={searchFun}>  
          
            {state === true ?  <IoCloseOutline id={styles.IoIoClose} onClick={clicksearch}/> :  <CiSearch id={styles.CiSearch} onClick={clicksearch}/>} 

              
            </li>
            <li className={styles.BpRcta}>
              <span onClick={handleShow} className={colors === true ? styles.promStzcolor : styles.promStz}> 
                <IoCartOutline id={styles.cart}/> 
               <div className={styles.g10fv}>{productsCount}</div>
       
              </span>
            </li>
            <div className={showCart ? styles.cartButtOpened : styles.cartButt}>
            <div className={showCart ? styles.openedCart : styles.closedCart} onClick={handleClose}> </div>
            <div className={showCart ? styles.showCart : styles.hideCart} onHide={handleClose}>
          
            <div className={styles.cartUl}>
              <span className={styles.p16flex}>
                <div className={styles.ult2}> 
             
                <div className={styles.p12in}>
                  <span><p>Your Cart ({productsCount})</p></span>
                </div>
                </div>
                <div className={styles.p06out}>
                <div className={styles.exitButtonOnNavbar}>
            <AiOutlineClose onClick={handleClose} className={styles.closingTag}/>
           
          </div>
                </div>
              </span>
              </div>
           
          <div className={productsCount > 0 ? styles.cartKXW : styles.cartKWX}>
            {productsCount > 0 ?

            <> 
            {cart.items.map((currentProduct, idx) => (
                <CartProduct id={currentProduct.id} quantity={currentProduct.quantity} key={idx}></CartProduct>
            ))}
           
            {/* <button onClick={checkout}>purchase items!</button> */}
                </>

                :

                <div className={styles.emptyCart}>
                  <h3 id={styles.emptyText}>
                    Your cart is empty.
                  </h3>
                </div>
                
            
        } </div>
         <div className={productsCount > 0 ? styles.totalPric : styles.totalPricnone} >
            <button className={styles.is54tl}><span><p id={styles.edbF}>
              Checkout • {cart.getTotalCost().toFixed(2)}&nbsp;£</p></span></button>
            </div> 
            </div>
        </div>
        </ul>
      </div>
    </div>   
    </div>
    </header>
  );
};
