import React, { useState, useMemo } from 'react'; 
import styles from '../styles.module.scss';
import { dataReviews } from '../dataReviews';
import { FaStar } from "react-icons/fa";

const ReviewTemplate = ({ posts }) => {

    const [reviews] = useState(dataReviews);
    const [numberOfitemsShown, setNumberOfItemsToShown] = useState(3);
  
    const showMore = () => {
      if (numberOfitemsShown + 3 <= reviews.length) {
        setNumberOfItemsToShown(numberOfitemsShown + 2);
      } else {
        setNumberOfItemsToShown(reviews.length);
      }
    };
  
    const itemsToShow = useMemo(() => {
      return reviews
        .slice(0, numberOfitemsShown)
        .map((data, index) => (
            
            <div className={styles.sa809}  key={index}>
                <div className={data.reviewImage ? styles.img52re : styles.img52ret}>
                       <img src={data.reviewImage}/>
                </div>
                <div className={styles.inf89s}>
                    <h3>{data.name}</h3>
                    <div className={styles.reviewNum4}>
                                                 <ul>
                                                    <li><FaStar/></li>
                                                    <li><FaStar/></li>
                                                    <li><FaStar/></li>
                                                    <li><FaStar/></li>
                                                    <li><FaStar/></li>
                                                 </ul>
                                            </div>
                    <p>{data.review}</p>
                </div>
            </div>
           ));
    }, [reviews, numberOfitemsShown]);
  
    return (
     <div className={styles.nam4912}>
        <div className={styles.nam253t}>
{itemsToShow.length ? itemsToShow : "Loading..."}

            </div> 
            <button className={styles.showMore} onClick={showMore}>View more</button>
            </div>
    
    );
  };

export default ReviewTemplate;
 