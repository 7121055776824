import React from "react"; 
import styles from './styles.module.scss'; 
import { Nav } from "../../components/Nav/Nav";  
import { Footer } from "../HomePagec/components/Footer/Footer";

const AboutUs = () => {
   

  return ( 
    <div className={styles.privacyPolicy}> 
      <Nav/>
    <div className={styles.priv482}>
    <h1 className={styles.privacyTitle}>
    Welcome to LikeCandy.co.uk
    </h1>
    <div className={styles.privacytx}>
        <span><p>Step into a realm of passion and allure at LikeCandy.co.uk, where we specialize in exquisite lingerie designed to evoke the goddess within every woman. We curate a collection that celebrates sensuality, seduction, and empowerment.
        </p></span> 
<br/>
<h5>Our Vision</h5>
<br/>
<span><p>
At LikeCandy.co.uk, we believe lingerie is a powerful expression of femininity and sensuality. Each piece in our collection is carefully chosen to enhance your natural allure, ignite confidence, and create unforgettable moments.
</p></span>
<br/>
<h5>Why Choose Us?</h5> 
<br/>
<span><p>Explore our


enchanting collection of lingerie crafted from luxurious fabrics, featuring sensual cuts, intricate lace, and provocative designs that evoke timeless allure.
</p></span>
<br/>
<h5>Embrace Your Inner Goddess:</h5>
<br/>
  <span><p>We
celebrate the uniqueness and beauty of every woman. Our lingerie is designed to make you feel like a goddess, empowering you to embrace your sensuality and confidence. </p>
<br/>
<h5>Personalized Experience:</h5>
<br/>
<span><p>
Our dedicated team provides a personalized shopping experience, ensuring you find lingerie that fits perfectly and enhances your journey of self-expression.

</p></span>
<br/>
<h5>Embrace Your Sensuality</h5>
<br/>
<span><p>
Discover lingerie that speaks to your


desires and enhances your intimate moments. Whether you're seeking to feel empowered or to ignite passion, LikeCandy.co.uk invites you to explore our seductive collection and embrace your inner goddess.
</p></span>
<br/>
<div class="info">
        
          <div className={styles.informationcontact}>
         <p>E-mail: </p>
         &nbsp;
            <p> info@likecandy.co.uk</p>
          </div>
          
           
        </div>
        <br/> </span>


    </div>
  </div> 
<Footer/>
  </div>
  );
};

export default AboutUs;

 