import React, { useContext, useState } from "react"; 
import styles from './styles.module.scss';
import { CartContext } from "../CardContext"; 
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { CgDetailsMore } from "react-icons/cg";
import { IoCartOutline } from "react-icons/io5"; 
import { FaCheck } from "react-icons/fa6";
import { productsArray } from "../products";

function ProductCard(props) {
 
    const product = props.product;
    const cart = useContext(CartContext);
    const productQuantity = cart.getProductQuantity(product.id);
    const [addedToCart, setAddedToCart] = useState(false); 
    const veliciNa = cart.getProductVelicina(product.velicina);
    console.log(veliciNa)
    return (
        <div className={styles.itemsContainer}> 
        <div className={styles.productCard}>
          
        <div className={styles.productImage}>
          <img src={product.imagethumbnail} id={styles.productImage}/>
          <div className={styles.productSize}>
          <span id={styles.sizeSpacing}>S/M</span> 
          </div>
        </div>
        
        <div className={styles.productDetails}> 
          <h2 className={styles.productTitle}>{product.title}</h2>
          <div className={styles.priceWrapper}>
          <p id={styles.productPriceDiscount}>{product.price}£</p>  
            </div> 
            <div className={styles.buttonsBottomBuy}>
          <div className={styles.buttonsDetailsBuy}> 
            <a className={styles.productButtonDetails}  href={`/analysis/${product.title}`}>Details <CgDetailsMore id={styles.details}/></a> 
          </div>
          
        
          </div>
        </div> 
      </div>  
    </div>
       
      
    )

}

export default ProductCard;