import React from 'react';  
import styles from './styles.module.scss'; 
import { AiOutlineClose } from "react-icons/ai"; 
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import classNames from 'classnames';

export const NavBarLink = ({ link, alt, name, classname }) => { 
  

    return (
         
        <li>
        <a
          exact activeClassName={styles.LinkDirectionActive}
          href={link}
          alt={alt}
          className={classname}
          id={styles.Home}
        > 
          {name}
        </a>
      </li>
              
    )
}
