import React, { useState, useEffect } from 'react';   
import styles from './styles.module.scss';    
import teaser1 from '../../../../images/teaser6.png';
import teaser2 from '../../../../images/provocativeset.png'


export const FeaturedCategories = ({onclick}) => {  
 

 return (
   <section className={styles.penman} onClick={onclick}>
       
      <div>
        <div className={styles.widget01}>
      <div className={styles.widget02}>
        <img src={teaser2} id={styles.sum1l}/>
        <div className={styles.mcPaj}>
          <h2 id={styles.werk57}>Why Choose Us?</h2>
          <h2 id={styles.werk58}>Discover our <i>Provocative</i> collection</h2>
          <p id={styles.its33}> Step into a world where mystery and allure intertwine effortlessly. Explore our collection where every piece whispers secrets of sensual elegance, inviting you to embrace the enigma within. Discover the art of seduction, wrapped in exquisite designs that celebrate your unique allure. Unveil the essence of mystery and sensuality with LikeCandy.co.uk</p>
          <a href="/products" className={styles.rve41}>Shop now</a>
        </div>
      </div>
      </div>
      </div>
    </section>
  );
};
