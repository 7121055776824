import React, { useState } from 'react';
import styles from './styles.module.scss'; 
import { Link } from 'react-router-dom'; 
import { Nav } from '../../components/Nav/Nav';  
import { LandingPage } from './components/Landing/LandingPage';
import { FeaturedCategories } from './components/FeaturedCategories/FeaturedCategories'; 
import { Notification } from '../../components/Nav/components/Notification/Notification';
import { OurHighlights } from './components/OurHighlights/OurHighlights';
import { Pleasure } from './components/Pleasure/Pleasure';
import { Subscribe } from './components/Subscribe/Subscribe';
import { Footer } from './components/Footer/Footer'; 

export const HomePage = () => {
  const [isReady, setIsReady] = useState(false);
  const [showSearches, setShowSearches] = useState(false); 

  const [showSearch, setShowSearch] = useState(false);
  const search = () => {
    setShowSearch(showSearch => !showSearch);
    
  };


  console.log(showSearch)

  return (
    <div className={styles.Wrapper} > 
        <Nav state={showSearch} searchFun={search} showSearchBar={showSearch}/>
        <Notification onclick={() => setShowSearch(false)}/>
        <LandingPage onclick={() => setShowSearch(false)}/>
        <FeaturedCategories onclick={() => setShowSearch(false)}/>
        <OurHighlights onclick={() => setShowSearch(false)}/>
        <Pleasure onclick={() => setShowSearch(false)}/>
        <Subscribe onclick={() => setShowSearch(false)}/> 
        <Footer/>  
    </div>
  );
}
