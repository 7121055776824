 import React, { useContext } from "react";
 import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { CartContext } from "./CardContext";
import { getProductData, productsArray } from "./products";
import styles from './styles.module.scss';
import purple from '../../../images/mirabelpurple.png';
import { FiMinus } from "react-icons/fi";
import { FiPlus } from "react-icons/fi";
import { TfiTrash } from "react-icons/tfi";

function CartProduct(props) {

  
    const { title } = useParams();
  const profile = productsArray.filter(profile => profile.title === title);    
    const cart = useContext(CartContext);
    const id = props.id;
    const product = props.product
    const quantity = props.quantity;
    const color = productsArray.selectedOption; 
    const productData = getProductData(id);  
    const boja = props.boja  
    const productQuantity = cart.getProductBoja(id); 
    const productColor = cart.getProductVelicina(id);


    return (
        <>
        <div className={styles.nt3350}>
            <div className={styles.fil12}>
            <div className={styles.cartPicture}>
             
 <img src={productData.image} id={styles.cartPhoto}/>
            
               
            </div>
            <div className={styles.xcEcl}>
                <div className={styles.vnxN}>
                  <div className={styles.rowP}>
                    <h3 id={styles.prodTitle}>{productData.title}</h3>
                    <h2 className={styles.prodColor}>Size: {productQuantity}</h2>
                    <h2 className={styles.prodColor}>Color: {productColor}</h2>
                    
                  </div>
                  <div className={styles.grad} onClick={() => cart.deleteFromCart(id)}>
                    <TfiTrash id={styles.trashcan}/>
                </div>
                </div>
              
                <div className={styles.d8e2}> 

                    <div className={styles.pCount}>
                        <div className={styles.minus} onClick={() => cart.removeOneFromCart(id)}>
                            <FiMinus id={styles.productMinus}/>
                        </div>
                        <div className={styles.currentProduct}>{quantity}</div>
                        <div  className={styles.plus} onClick={() => cart.addOneToCart(id)}>
                            <FiPlus id={styles.productPlus}/>
                        </div>
                    </div>

                    <div className={styles.productPrice}>{(quantity * productData.price).toFixed(2) }</div>
                </div>
            </div>
            {/* <h3></h3>
            <p>{quantity} total</p>
            <p>${ (quantity * productData.price).toFixed(2) }</p>
            <button onClick={() => cart.addOneToCart(id)}>+</button>
            <button onClick={() => cart.deleteFromCart(id)}>Remove</button>
            <hr></hr> */}
            </div>
        
        </div>
            
        </>
    )
}

export default CartProduct;