import React, { useState } from 'react';
import styles from './styles.module.scss';  
import NavbarComponent from '../components/NavBar';
import { productsArray } from '../components/products';
import ProductCard from '../components/ProductCard/ProductCard';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export const Store = (posts) => {
  
  const { showProducts } = useParams();
  const profile = productsArray.filter(profile => profile.showProducts === showProducts); 
  console.log(profile) 
  return (
    <div className={styles.Wrapper}>   
    <div className={styles.grid}>
      
    {profile.map((product, idx) => (
      <div key={idx}>
         <ProductCard product={product}/>
        </div>
     
    ))}
    

    </div>
    </div>
  );
}
