import React, {useEffect, useState} from 'react'; 
import styles from './styles.module.scss';   
import { BsArrowDownRight } from "react-icons/bs";
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-multi-carousel';
import leftarrow from '../../../../images/left-arrow-white.svg';
import rightarrow from '../../../../images/right-arrow-white.svg';
import carousellanding from '../../../../images/temna.jpg';
import carousellandingmobile from '../../../../images/ladnging.png';
import carousellanding2 from '../../../../images/landing6.png';
import carousellanding1 from '../../../../images/bcg251.jpeg'

export const Notification = ({onclick}) => {
 
  
  const [isGreaterThan900px, setIsGreaterThan900px] = useState(false);
  console.log(isGreaterThan900px)
  React.useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 600) {
        setIsGreaterThan900px(true);
        
      } else {
        setIsGreaterThan900px(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);


  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 600 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }, 
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  const CustomRightArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType }
    } = rest;
    // onMove means if dragging or swiping in progress.
    return <div className={styles.styledArrowRight} onClick={() => onClick()}><img src={rightarrow}/></div>;
  };

  const CustomLeftArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType }
    } = rest;
    // onMove means if dragging or swiping in progress.
    return <div className={styles.styledArrowLeft} onClick={() => onClick()}><img src={leftarrow}/></div>;
  };

  const CustomDot = ({ onClick, ...rest }) => {
    const {
      onMove,
      index,
      active,
      carouselState: { currentSlide, deviceType }
    } = rest; 
    // onMove means if dragging or swiping in progress.
    // active is provided by this lib for checking if the item is active or not.
    return (
      <li className={styles.yt01c} >
        <div className={active ? styles.gcdp2u : styles.gcdp2}  ></div>
      </li>
    );
  };

  return (
    <section className={styles.marquee1029} onClick={onclick}>
      
      <div className={styles.carouselContainer}
     >
      
       
       <span>
       <div className={styles.ent7141}> 
      <h4 className={styles.ipt49}>  
      Elevate your allure with lingerie that defines elegance and empowers confidence
      </h4>
      <a href="/products" className={styles.dth89}>
        Shop now
        <BsArrowDownRight id={styles.arrowright}/>
      </a>
      </div>
      <img src={carousellanding1} id={styles.pmGn2}/>
       
       </span>

{/* 
       <img src={isGreaterThan900px ? carousellanding : carousellandingmobile} id={styles.pmGn1}/>  */}

{/* 
       <span className={styles.dotom}>
      <img src={carousellanding2} id={styles.pmGn2}/>
      <div className={styles.ent7141h}> 
      <span className={styles.gpUDA}>  
        <h4 id={styles.Yoj1}>Fuse your</h4>  
        <span className={styles.mal010}>
          <span className={styles.dNZ9p}>
          <h3 id={styles.ph7z}>Allure</h3> 
        <h4 id={styles.Yoj12}>&</h4>
          </span> 
        <h3 id={styles.tnUIq}>Seduction</h3> 
        </span>
       
      </span>
      <a href="/products" className={styles.dth89}>
        Shop now
        <BsArrowDownRight id={styles.arrowright}/>
      </a>
      </div>
      </span> */}
     </div>
      
    </section>
  );
}
