
import React, { useState, useEffect } from 'react';
import "./style.css";
import styles from './styles.module.scss'; 
import { BsSliders } from "react-icons/bs";
import { IoCloseCircleOutline } from "react-icons/io5";
import styled from 'styled-components';
import ProductTemplate from './productTemplate/productTemplate'; 
import { Nav } from '../../components/Nav/Nav';
import headline from '../../images/headline.jpg'; 
import { Store } from '../../pages/store/pages/Store';
import { Footer } from '../HomePagec/components/Footer/Footer';
import { setSelectionRange } from '@testing-library/user-event/dist/utils';

export const ProductsPage = () => {
   

  const [showSearch, setShowSearch] = useState(false);
  const search = () => {
    setShowSearch(showSearch => !showSearch);
    
  }; 
  return (
    <>
    <div className={styles.productWrapper}>
    <Nav state={showSearch} searchFun={search} showSearchBar={showSearch}/>
    <div className={styles.productFlex}>
      <ul className={styles.med97}>
        <li ><span id={styles.hpG}><a href="/">Home Page</a></span></li>
        
        <li><span  id={styles.ppG}>Products</span></li>
      </ul>
    </div>
    <div className={styles.descR} onClick={() => setShowSearch(false)}>
        <div className={styles.unt24}>
        <span className={styles.heD}> 
                <span className={styles.headlineT}>
                    Like Candy
                </span>
                <span className={styles.headlineB}>
                    Lingerie
                </span>
            </span>
        <p id={styles.headLP}>Unleash your inner desire with our sensuous collection of erotic lingerie. Seduction is redefined as you discover lingerie that speaks the language of passion. Feel the heat and indulge in our alluring and erotic lingerie line, designed to ignite the night. Temptation awaits—dare to bare and elevate your intimate moments with our exquisite pieces.</p>
        </div>
        <div className={styles.headPic}>
            <img src={headline} id={styles.headPho}/>
        </div>
    </div>
    <div className={styles.allPContainer} onClick={() => setShowSearch(false)}>
      <div className={styles.allPcounts}>
        <span id={styles.po13i}>All Products</span>
        <span id={styles.po13i}>Showing 1-5</span>
      </div>
      <div className={styles.allPs}>
        <Store/>
      </div>
    </div>
    <Footer/>
     </div>
    </>
  );
};
  
