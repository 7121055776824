import React, { useState, useEffect } from 'react';   
import styles from './styles.module.scss';   
import Carousel from 'react-multi-carousel';
import day30 from '../../../../images/30day.jpg';
import day30mobile from '../../../../images/30daymobile.jpg';
import "react-multi-carousel/lib/styles.css";
import rightarrow from '../../../../images/right-arrow-white.svg';
import leftarrow from '../../../../images/left-arrow-white.svg';
import unleash from '../../../../images/sexy.png';
import your from '../../../../images/elegantt.png';
import fantasies from '../../../../images/wild.png';
import './style.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


export const LandingPage = ({onclick}) => {  

    const [isGreaterThan1000px, setIsGreaterThan1000px] = useState(false);
  
    React.useEffect(() => {
      function handleResize() {
        if (window.innerWidth > 1000) {
          setIsGreaterThan1000px(true);
          
        } else {
          setIsGreaterThan1000px(false);
        }
      }
  
      handleResize();
  
      window.addEventListener("resize", handleResize);
  
      return () => window.removeEventListener("resize", handleResize);
    }, []);
 

    const responsive = {
      desktop: {
        breakpoint: { max: 1000, min: 600 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
      }, 
      mobile: {
        breakpoint: { max: 600, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
      }
    };

    const CustomRightArrow = ({ onClick, ...rest }) => {
      const {
        onMove,
        carouselState: { currentSlide, deviceType }
      } = rest;
      // onMove means if dragging or swiping in progress.
      return <div className={styles.styledArrowRight} onClick={() => onClick()}><img src={rightarrow}/></div>;
    };

    const CustomLeftArrow = ({ onClick, ...rest }) => {
      const {
        onMove,
        carouselState: { currentSlide, deviceType }
      } = rest;
      // onMove means if dragging or swiping in progress.
      return <div className={styles.styledArrowLeft} onClick={() => onClick()}><img src={leftarrow}/></div>;
    };

    const CustomDot = ({ onClick, ...rest }) => {
      const {
        onMove,
        index,
        active,
        carouselState: { currentSlide, deviceType }
      } = rest; 
      // onMove means if dragging or swiping in progress.
      // active is provided by this lib for checking if the item is active or not.
      return (
        <li className={styles.yt01c} >
          <div className={active ? styles.gcdp2u : styles.gcdp2}  ></div>
        </li>
      );
    };

 return (
   <section className={styles.froEnt} onClick={onclick}>

    {isGreaterThan1000px ? <><div className={styles.lg4Le}>
       
     </div>
     <div className={styles.g14129}>
      <div className={styles.tre5}> 
        <h4 id={styles.mes29}>Unleash your inner</h4> 
       
        <h4 id={styles.class9}>goddess</h4>
      </div>
      <div className={styles.carouselContainer}
     >
      <a href="/products" id={styles.unl24}>
      <img src={your} id={styles.un24}/>
      </a>
      <a href="/products" id={styles.unl23}>
      <img src={unleash} id={styles.un24}/>
      </a>
      <a href="/products"  id={styles.unl25}>
      
      <img src={fantasies} id={styles.un24}/>
      </a>
      
      </div>
     </div></> : <><div className={styles.lg4Le}>
      <div className={styles.lock7}>
      <span className={styles.glu8en}>
        Explore
      </span> 
      </div>
     </div>
     <div className={styles.g14129}>
      <div className={styles.tre5}> 
      <span className={styles.bsalt}>
        Ready?
      </span>
        <h4 id={styles.mes29}>Unleash your inner</h4> 
       
        <h4 id={styles.class9}>goddess</h4>
      </div> <Carousel
     containerClass={styles.carouselContainer}
     responsive={responsive}
     itemClass={styles.carouselItem}
     infinite={true}
     customRightArrow={<CustomRightArrow />}
     customLeftArrow={<CustomLeftArrow />} 
     autoPlay={true}
     autoPlaySpeed={7000} 
     transitionDuration={300} 
     arrows={false} 
     showDots
     customDot={<CustomDot />}
     >
<img src={your} id={styles.unl24}/>
      
      <img src={unleash} id={styles.unl23}/>
      <img src={fantasies} id={styles.unl25}/>
      </Carousel> 
      
      </div></>}
      
    </section>
  );
};
