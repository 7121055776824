import React from "react"; 
import styles from './styles.module.scss'; 
import { Nav } from "../../components/Nav/Nav";  
import { Footer } from "../HomePagec/components/Footer/Footer";

const PrivacyPolicy = () => {
   

  return ( 
    <div className={styles.privacyPolicy}> 
      <Nav/>
    <div className={styles.priv482}>
    <h1 className={styles.privacyTitle}>
    Privacy Policy for LikeCandy.co.uk
    </h1>
    <div className={styles.privacytx}>
        <h5>1. Introduction</h5>
        <br/>
        <span><p>Welcome to LikeCandy.co.uk! We are
committed to protecting your privacy and
ensuring you have a positive experience on
our website. This policy explains how we
collect, use, disclose, and safeguard your
information when you visit our website.

        </p></span> 
<br/>
<h5>2. Information We Collect</h5>
<br/>
<span><p>
We may collect and process the following
data about you:
</p></span>
<br/>
<ul id={styles.ul2}>
  <li><p id={styles.ecis56}>Information you provide when placing an
order, such as your name, email address,
postal address, and payment information.
</p></li>

  <li><p id={styles.ecis56}>Information you provide when contacting
us through customer service inquiries or
 
feedback forms.
</p></li>

  <li><p id={styles.ecis56}>Details of your visits to our website and
the resources that you access, including
traffic data, location data, weblogs, and
other communication data.
</p></li> 
</ul> 
<br/>
<h5>3. How We Use Your Information</h5> 
<br/>
<span><p>We use the information we collect in the
following ways:

</p></span> 
<br/>
<ul id={styles.ul2}>
  <li><p id={styles.ecis56}>To process and fulfill your orders,
including communicating with you about
your order status.

</p></li>

  <li><p id={styles.ecis56}>To provide customer support and
respond to your inquiries.

</p></li>

  <li><p id={styles.ecis56}>To send you information about our
products, promotions, or special offers if
you have subscribed to our newsletter or
opted in to receive marketing
communications.

</p></li> 
</ul> 
<br/>
<h5>4. Legal Basis for Processing</h5>
<br/>
  <span><p>The legal basis for processing your
personal data is typically one of the
following:
</p>
<br/>
<ul id={styles.ul2}>
  <li><p id={styles.ecis56}>Performance of a contract: Processing
necessary to fulfill an order or carry out
our obligations arising from any contracts
entered into between you and us.


</p></li>

  <li><p id={styles.ecis56}> Consent: Processing where you have
provided your explicit consent for specific
purposes, such as receiving marketing
communications.


</p></li>

  <li><p id={styles.ecis56}>Legitimate interests: Processing
necessary for our legitimate interests as a
business, provided these interests are not
overridden by your rights and interests.
We do not sell, trade, or otherwise transfer
your personal information to outside
parties except as set out in this Privacy
Policy or unless we have your permission
or are required by law to do so.
We may share your information with:

<ul id={styles.ul2}>
<br/>
  <li><p id={styles.ecis56}>Service providers who assist us in
operating our website or conducting our
business, such as payment processors
and shipping companies.



</p></li>

  <li><p id={styles.ecis56}> Legal authorities if required to comply
with a legal obligation or to protect the
rights, property, or safety of our customers
or others.



</p></li>
 
</ul>  

</p></li> 
</ul> 
<br/>
<h5>5. Your Rights</h5>
<br/>
<ul id={styles.ul2}>
  <li><p id={styles.ecis56}>Access the personal information we hold
about you.


</p></li>

  <li><p id={styles.ecis56}>Rectify any inaccurate or incomplete
  information.

</p></li>

  <li><p id={styles.ecis56}>Request erasure of your personal
information under certain conditions.


</p></li> 
<li><p id={styles.ecis56}>Restrict or object to processing of your
personal information under certain
conditions.



</p></li> 
<li><p id={styles.ecis56}>Receive your personal information in a
structured, commonly used, and machine-
readable format.



</p></li>  
</ul> 
<span><p>To exercise these rights, please contact us
using the details provided below.</p></span>
<br/>
<h5>6. Data Security</h5>
<br/>
<span><p>
We are committed to ensuring that your information is secure. We have
implemented appropriate technical and organizational measures to safeguard and secure the information we collect online.

</p></span>
<br/>
<h5>7. Cookies</h5>
<br/>
<span><p>
Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and allows us to improve our site. For detailed information on the cookies we use and the purposes for which we use them, please see our Cookie Policy.


</p></span>
<br/>
<h5>8. Changes to This Privacy Policy</h5>
<br/>
<span><p>
We may update this Privacy Policy from time to time to reflect changes to our practices or for other operational, legal, or
regulatory reasons. We encourage you to periodically review this page for the latest information on our privacy practices.


</p></span>
<br/>
<h5>9. Contact Us</h5>
<br/>
<span><p>
If you have any questions about this Privacy Policy or the information we hold about you, please contact us: info@likecandy.co.uk


</p></span>
<br/>

<br/>
 
 
  </span>


    </div>
  </div> 
<Footer/>
  </div>
  );
};

export default PrivacyPolicy;

 