import React, { useState } from 'react'; 
import { FiSearch } from 'react-icons/fi';
import { Input } from '../Input/Input';
import styles from './styles.module.scss';

export function SearchBar({clicked, showSearchBar}) {
 
 
  return (
    <div id={showSearchBar ? styles.cover : styles.coverWidth}>
        <div className={styles.otRUp}>

       
     
        
              <Input clicked={clicked} className={showSearchBar ? styles.searchInput : styles.searchInputN} classN={showSearchBar ? styles.bbForm : styles.bbFormN} classM={showSearchBar}/>
          
              </div>
              <a href="/products">
              <button className={showSearchBar ? styles.searchIcon : styles.searchIconN}>
            <FiSearch id={styles.bsSearch} />
          </button>
              </a>
          
        </div>
     
   
  );
}
  
