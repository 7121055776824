import React, { useState, useRef } from 'react';
import { productsArray } from '../../../../pages/store/components/products';
import { ProfileItems } from '../Profile/ProfileItems';
import styles from './styles.module.scss';
import { trendingdata } from '../../../Analysis/trendingproducts';
import wedding from '../../../../images/wedding.svg';
import { Link } from 'react-router-dom';
import { popularcategories } from '../../../Analysis/popularcategories';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export function Input({className, classN, classM}) { 

  const { showProducts } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const profile = productsArray.filter(profile => profile.showProducts === showProducts); 
  

  return (
    <div className={!isFocused && !classM ? styles.hideZ : styles.showC}>
    <form className={classN} onFocus={() => {
        setIsFocused(true);
      }}
      onBlur={() => {
       setTimeout(function(){setIsFocused(false);}, 100)
      }}
      tabIndex="0">
    
        <input  
          className={className}
          type="text" 
          placeholder="Search" 
          onChange={event => { setSearchTerm(event.target.value); }} 
          required
        />
     
     
    </form>
    <div className={!isFocused && !classM ? styles.search_header_CategoriesHide : styles.search_header_Categories}>
       
        {profile.filter((val) => {
          if (searchTerm === '') {
            return null;
          }  
          if (val.title.toLowerCase().startsWith(searchTerm.toLowerCase())) {
            return val;
          } return null;
        }).map((val, key) => (
          <ProfileItems
            key={key}
            title={val.title}
            // parfumePrice={val.parfumePrice}
            parfumeWhiteThumbnail={val.image}
          />
        ))}
        <div className={isFocused && !searchTerm ? styles.show : styles.hide}>
            <h3 className={styles.suggestedSearch}>
                Trending products
            </h3>
            {trendingdata.map((trending, key) => (
                <ProfileItems
                  key={key}
                  title={trending.title}
                  parfumeWhiteThumbnail={trending.parfumeWhiteThumbnail}
                />
            ))}
              
            
        </div>
      </div>
      
    </div>
  );
}
  
