import React, { useState, useEffect } from 'react';   
import styles from './styles.module.scss';    
import Carousel from 'react-multi-carousel';
import day30 from '../../../../images/30day.jpg';
import day30mobile from '../../../../images/30daymobile.jpg';
import "react-multi-carousel/lib/styles.css";
import rightarrow from '../../../../images/right-arrow-white.svg';
import leftarrow from '../../../../images/left-arrow-white.svg';
import purple from '../../../../images/mirabellepurple.png';
import './style.css';
import arrow from '../../../../images/up-arrow.png';
import { HiArrowLongDown } from "react-icons/hi2";


export const OurHighlights = ({onclick}) => {  
 
    const [isGreaterThan1200px, setIsGreaterThan1200px] = useState(false);
  
    React.useEffect(() => {
      function handleResize() {
        if (window.innerWidth > 1200) {
          setIsGreaterThan1200px(true);
          
        } else {
          setIsGreaterThan1200px(false);
        }
      }
  
      handleResize();
  
      window.addEventListener("resize", handleResize);
  
      return () => window.removeEventListener("resize", handleResize);
    }, []);
 

    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1200 },
        items: 4,
        slidesToSlide: 1 // optional, default to 1.
      }, 
      tablet: {
        breakpoint: { max: 1200, min: 900 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 900, min: 500 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
      },
      scroll: {
        breakpoint: { max: 500, min: 200 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
      }
    };

    const CustomRightArrow = ({ onClick, ...rest }) => {
      const {
        onMove,
        carouselState: { currentSlide, deviceType }
      } = rest;
      // onMove means if dragging or swiping in progress.
      return <div className={styles.styledArrowRight} onClick={() => onClick()}><HiArrowLongDown id={styles.arrowright}/></div>;
    };

    const CustomLeftArrow = ({ onClick, ...rest }) => {
      const {
        onMove,
        carouselState: { currentSlide, deviceType }
      } = rest;
      // onMove means if dragging or swiping in progress.
      return <div className={styles.styledArrowLeft} onClick={() => onClick()}><HiArrowLongDown id={styles.arrowleft}/></div>;
    };

    const CustomDot = ({ onClick, ...rest }) => {
      const {
        onMove,
        index,
        active,
        carouselState: { currentSlide, deviceType }
      } = rest; 
      // onMove means if dragging or swiping in progress.
      // active is provided by this lib for checking if the item is active or not.
      return (
        <li className={styles.yt01c} >
          <div className={active ? styles.gcdp2u : styles.gcdp2}  ></div>
        </li>
      );
    };


 return (
   <section className={styles.highlights} onClick={onclick}>
    <div className={styles.lock7}>
      <span className={styles.glu8en}>
        Popular
      </span> 
      </div>
   <Carousel
     containerClass={styles.carouselContainer}
     responsive={responsive}
     itemClass={styles.carouselItem}
     infinite={false}
     customRightArrow={<CustomRightArrow />}
     customLeftArrow={<CustomLeftArrow />} 
     autoPlay={false}
     autoPlaySpeed={7000} 
     transitionDuration={300} 
     arrows={true} 
     showDots={isGreaterThan1200px ? false : true}
     customDot={<CustomDot />}
     >
   <div className={styles.py3sl0}>
    <a href="https://www.kriory.sk/analysis/Blissfull%20Bite" className={styles.xlde}>
         <div id={styles.hlp}></div>
    </a>
    <h3 className={styles.css14}>
        <a href="https://www.kriory.sk/analysis/Blissfull%20Bite" className={styles.erb29}>
            Blissfull bite
        </a>
    </h3>
    <a href="https://www.kriory.sk/analysis/Blissfull%20Bite" className={styles.ght25}>
    Like Candy
    </a>
    <div className={styles.ider0}>
        <h4 id={styles.price}>
        34.99&nbsp;£
        </h4>
    </div>
   </div>
   <div className={styles.py3sl0}>
    <a href="https://www.kriory.sk/analysis/Lollipop" className={styles.xlde}>
         <div id={styles.hlp2}></div>
    </a>
    <h3 className={styles.css14}>
        <a href="https://www.kriory.sk/analysis/Lollipop" className={styles.erb29}>
        Lollipop
        </a>
    </h3>
    <a href="https://www.kriory.sk/analysis/Lollipop" className={styles.ght25}>
    Like Candy
    </a>
    <div className={styles.ider0}>
        <h4 id={styles.price}>
        34,99&nbsp;£
        </h4>
    </div>
   </div>
   <div className={styles.py3sl0}>
    <a href="https://www.kriory.sk/analysis/Cotton%20Candy" className={styles.xlde}>
         <div id={styles.hlp3}></div>
    </a>
    <h3 className={styles.css14}>
        <a href="https://www.kriory.sk/analysis/Cotton%20Candy" className={styles.erb29}>
            Cotton Candy
        </a>
    </h3>
    <a href="https://www.kriory.sk/analysis/Cotton%20Candy" className={styles.ght25}>
    Like Candy
    </a>
    <div className={styles.ider0}>
        <h4 id={styles.price}>
        34,99&nbsp;£
        </h4>
    </div>
   </div>
   <div className={styles.py3sl0}>
    <a href="https://www.kriory.sk/analysis/Fairytale%20Flavour" className={styles.xlde}>
         <div id={styles.hlp4}></div>
    </a>
    <h3 className={styles.css14}>
        <a href="https://www.kriory.sk/analysis/Fairytale%20Flavour" className={styles.erb29}>
           Fairytale Flavour
        </a>
    </h3>
    <a href="https://www.kriory.sk/analysis/Fairytale%20Flavour" className={styles.ght25}>
    Like Candy
    </a>
    <div className={styles.ider0}>
        <h4 id={styles.price}>
        34,99&nbsp;£
        </h4>
    </div>
   </div>
      </Carousel> 
    </section>
  );
};
