import React, { useState, useContext } from 'react'; 
import styles from './styles.module.scss';
import { CartContext } from './CardContext';
import CartProduct from './CartProduct';




function NavbarComponent() {

    const cart = useContext(CartContext);

    const [ show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const checkout = async () => {
        await fetch('http://localhost:4000/checkout', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({items: cart.items})
        }).then((response) => {
            return response.json();
        }).then((response) => {
            if(response.url) {
                window.location.assign(response.url); // Forwarding user to Stripe
            }
        });
    }


    const productsCount = cart.items.reduce((sum, product) => sum + product.quantity, 0)

    return (
        <> 

        </>
    )
}

export default NavbarComponent;