import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

export function ProfileItems({ title, parfumeWhiteThumbnail, parfumeBrand, parfumeModel, parfumeGender, parfumePrice }) {
  return (
    <div className={styles.search_header_Test}>
      <a href={`/analysis/${title}`} className={styles.eetTi}>
         
            <div className={styles.search_header_Picture}>
              <img src={parfumeWhiteThumbnail} />
            </div>
            <div className={styles.search_header_Text}>
              <p id={styles.search_text_Brand}>{title}</p> 
              <p id={styles.search_text_Price}>34,99£</p>
            </div>
          
      </a> 
    </div>
  );
}
