import React, { createContext, useState } from "react";
import { productsArray, getProductData } from "./products"; 
import { useParams } from "react-router-dom/cjs/react-router-dom.min";


export const CartContext = createContext({
    items: [],
    getProductQuantity: () => {}, 
    handleStatusChange: () => {},
    getProductBoja: () => {},
    getProductVelicina: () => {},
    addOneToCart: () => {},
    removeOneFromCart: () => {},
    deleteFromCart: () => {},
    getTotalCost: () => {},  
    handleStatusChange: () => {},
    handleVelicina: () => {},
    setM: () => {},
    setRed: () => {},
    setBlue: () => {},
    initialState: []
});
 
 
export function CartProvider({children}) {
   
 
    const [cartProducts, setCartProducts] = useState(localStorage.getItem("MY_CART") ? JSON.parse(localStorage.getItem("MY_CART")) : []); 
    const initialState = {
        items: cartProducts
    }
    const [cartList, setCartList] = useState(cartProducts)
     

    // [ { id: 1 , quantity: 3 }, { id: 2, quantity: 1 } ] 
    function getProductQuantity(id) {
        const quantity = cartProducts.find(product => product.id === id)?.quantity;
        
        if (quantity === undefined) {
            return 0;
        } 
        return quantity;
    }

    const [listReceiver, setListReceiver] = useState([]);
    function getProductBoja(id) {
        const boja = cartProducts.find(product => product.id === id)?.boja;
        
        if (boja === undefined) {
            return "S";
        }

        return boja;
        
      
    }

    function getProductVelicina(id) {
        const velicina = cartProducts.find(product => product.id === id)?.velicina;
        
        if (velicina === undefined) {
            return "Default";
        }

        return velicina;
      
    }

 
  
    const [size, setSize] = useState(productsArray); 
    const [velicina, setVelicina] = useState(productsArray);
     
    function setM(id) {
        console.log("M")
        setSize(prevState => {
           return console.log("123123")
        });
    }
    const handleStatusChange = (e,id) => {   
      
        
      const { value } = e.target; 
      
      setSize(prevState => {
        
          options => options.value === value
        
        return { selectedOption: value };
      });
    };  
     
    const handleVelicina = (e,id) => {   
      
        
        const { value } = e.target; 
        e.target.value = e.target.value.trimStart().trimEnd();
        setVelicina(prevState => {
          
            options => options.value === value
          
          return { selectedVelicina: value };
        });
      };  

      const setRed = (e) => {
        setVelicina(prevState => {
          
             
          
          return { selectedVelicina: "Red" };
        });
        
      }

      const setBlue = (e) => {
        setVelicina(prevState => {
          
             
          
          return { selectedVelicina: "Blue" };
        });
        
      }

     
      
 
  
     
    function addOneToCart(id) {
        const quantity = getProductQuantity(id);   
        const boja = getProductBoja(id);   
        if (quantity === 0) { // product is not in cart
            setCartProducts(
                [
                    ...cartProducts,
                    {
                        id: id,
                        quantity: 1, 
                        boja: size.selectedOption,
                        velicina: velicina.selectedVelicina
                    }
                ]
            )  
            
        } else { // product is in cart
            // [ { id: 1 , quantity: 3 }, { id: 2, quantity: 1 } ]    add to product id of 2 
            setCartProducts(
                cartProducts.map(
                    product =>
                    product.id === id                                // if condition
                    ? { ...product, 
                        quantity: product.quantity + 1, 
                        boja: product.boja, 
                        velicina: product.velicina } // if statement is true
                    : product                                        // if statement is false
                )
            )
        } 
    }

    function removeOneFromCart(id) {
        const quantity = getProductQuantity(id);

        if(quantity == 1) {
            deleteFromCart(id);
        } else {
            setCartProducts(
                cartProducts.map(
                    product =>
                    product.id === id                                // if condition
                    ? { ...product, quantity: product.quantity - 1, velicina: product.velicina, boja: product.boja } // if statement is true
                    : product                                        // if statement is false
                )
                
            )
        } 
        
    }

    function deleteFromCart(id) {
        // [] if an object meets a condition, add the object to array
        // [product1, product2, product3]
        // [product1, product3]
        setCartProducts(
            cartProducts =>
            cartProducts.filter(currentProduct => {
               
                return currentProduct.id != id;
                
            })  
        )  
         
    }

    function getTotalCost() {
        let totalCost = 0;
        cartProducts.map((cartItem) => {
            const productData = getProductData(cartItem.id);
            totalCost += (productData.price * cartItem.quantity);
        });
        return totalCost;
    }

    const contextValue = {
        items: cartProducts,
        getProductQuantity,
        addOneToCart,
        removeOneFromCart,
        deleteFromCart,
        getTotalCost, 
        getProductBoja,
        handleStatusChange,
        getProductVelicina,
        handleVelicina,
        setRed,
        setBlue,
        size,
        velicina,
        setM,
        initialState: initialState.items
        
    }
 

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    )
}

export default CartProvider;


// CODE DOWN HERE

// Context (cart, addToCart, removeCart)
// Provider -> gives your React app access to all the things in your context