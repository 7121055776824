import React from "react"; 
import styles from './styles.module.scss'; 
import { Nav } from "../../components/Nav/Nav";  
import { Footer } from "../HomePagec/components/Footer/Footer";

const TermsOfUse = () => {
   

  return ( 
    <div className={styles.privacyPolicy}> 
      <Nav/>
    <div className={styles.priv482}>
    <h1 className={styles.privacyTitle}>
    Terms and Conditions
    </h1>
    <div className={styles.privacytx}>
        <h5>Introduction</h5>
        <br/>
        <span><p>Welcome to LikeCandy.co.uk
            <br/>These Terms and Conditions govern your use of our website, LikeCandy.co.uk , and any purchases you make from us. 
            <br/>By accessing or using the Website, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Website.</p></span> 
<br/>
<h5>1. Use of the Website</h5>
<br/>
<ul id={styles.ul885}>
  <li><p id={styles.ecis56}>1.1 You must be at least 18 years old to use the Website.
</p></li>

  <li><p id={styles.ecis56}>1.2 You agree to use the Website only for lawful purposes and in accordance with these Terms.
</p></li>

  <li><p id={styles.ecis56}>1.3 You are responsible for maintaining the confidentiality of your payment details and for all activities that occur under your usage.
</p></li> 
</ul>  
<br/>
<h5>2. Product Information</h5> 
<br/>
<ul id={styles.ul885}>
  <li><p id={styles.ecis56}>2.1 We strive to display our products accurately. However, we do not guarantee that the colours, sizes, and details provided are error-free.
</p></li>

  <li><p id={styles.ecis56}>2.2 All products are subject to availability,and we reserve the right to limit the quantity of products we supply, supply only part of an order, or divide orders into multiple shipments.
</p></li>
 
</ul> 
<br/> 
<h5>3. Orders and Payments</h5>
<br/>
<ul id={styles.ul885}>
  <li><p id={styles.ecis56}>3.1 All orders placed through the Website are subject to acceptance by us.
</p></li>

  <li><p id={styles.ecis56}>3.2 We accept payments via debit card and PayPal only.
</p></li>
<li><p id={styles.ecis56}>3.3 Prices are subject to change withoutnotice. All prices include VAT unlessotherwise stated.
</p></li>
<li><p id={styles.ecis56}>
3.4 You agree to provide current, complete,and accurate purchase and paymentinformation for all purchases made via theWebsite.

</p></li>
 
</ul> 
<br/> 
<h5>4. Shipping and Delivery</h5>
<br/>
<ul id={styles.ul885}>
  <li><p id={styles.ecis56}>4.1 We offer one shipping method with an estimated delivery time of 7 to 10 days once the order has been shipped.
</p></li>

  <li><p id={styles.ecis56}>4.2 Delivery dates are estimates and not guaranteed. We are not responsible for delays due to unforeseen circumstances.
</p></li>
 
</ul> 
<br/>
<h5>5. Refunds and No Returns</h5>
<br/>
<ul id={styles.ul885}>
  <li><p id={styles.ecis56}>5.1 Due to the intimate nature of our products, we do not accept returns. However, we offer refunds under specific circumstances.
</p></li>

  <li><p id={styles.ecis56}>5.2 If you receive a damaged or defective product, please contact us within 14 days of receipt with your order number and details of the issue.
</p></li>
<li><p id={styles.ecis56}>
5.3 Refunds will be processed within 14days of verifying the claim. The refunded amount will be credited to the original method of payment.
</p></li>
</ul>  
<br/>
<h5>6. Intellectual Property</h5>
<br/>
<ul id={styles.ul885}>
  <li><p id={styles.ecis56}>6.1 All content on the Website, including text, graphics, logos, images, and software, is our property or the property of our Privacy licensors and is protected by UK and international copyright laws.
</p></li>

  <li><p id={styles.ecis56}>6.2 You may not use, reproduce, distribute, or create derivative works from any content on the Website without our prior written permission.
</p></li> 
</ul> 
<br/>
<h5>7. Limitation of Liability</h5>
<br/>
<ul id={styles.ul885}>
  <li><p id={styles.ecis56}>7.1 To the extent permitted by law, we shall not be liable for any direct, indirect, incidental, or consequential damages arising out of your use of the Website or purchase of products from us.
</p></li>

  <li><p id={styles.ecis56}>7.2 Our total liability to you for any claim arising out of or in connection with these Terms shall not exceed the amount you paid for the product.
</p></li> 
</ul> 
<br/>
<h5>
8. Privacy</h5>
<br/>
<ul id={styles.ul885}>
  <li><p id={styles.ecis56}>8.1 Your use of the Website is also governed by our Privacy Policy, which can be found <a id={styles.ppolicy}href="/privacypolicy">here</a>.
</p></li>

  <li><p id={styles.ecis56}>8.2 By using the Website, you consent to the processing of your personal data as described in the Privacy Policy.
</p></li> 
</ul> 
<br/>
<h5> 9. Changes to Terms</h5>
<br/>
<ul id={styles.ul885}>
  <li><p id={styles.ecis56}>9.1 We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting on the Website.
</p></li>

  <li><p id={styles.ecis56}>9.2 Your continued use of the Website after any such changes constitutes your acceptance of the new Terms.
</p></li> 
</ul> 
<br/>
<h5> 10. Governing Law</h5>
<br/>
<ul id={styles.ul885}>
  <li><p id={styles.ecis56}>10.1 These Terms are governed by and construed in accordance with the laws of
  England and Wales.
</p></li>

  <li><p id={styles.ecis56}>10.2 Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of England and Wales.
</p></li> 
</ul> 
<br/>
<h5>11. Contact Information</h5>
<br/>
<span><p>If you have any questions or concerns about these Terms, please contact us at:</p></span>
<br/>
<div class="info"> 
          <div className={styles.informationcontact}>
         <p>E-mail: </p>
         &nbsp;
            <p> info@likecandy.co.uk</p>
          </div> 
          
           
        </div>
        <br/>
<p id={styles.pppfff}>Thank you for visiting LikeCandy.co.uk. We hope you enjoy your shopping experience!</p> 


    </div>
  </div> 
<Footer/>
  </div>
  );
};

export default TermsOfUse;

 