import React from "react"; 
import styles from './styles.module.scss'; 
import { Nav } from "../../components/Nav/Nav";  
import { Footer } from "../HomePagec/components/Footer/Footer";

const Shipping = () => {
   

  return ( 
    <div className={styles.privacyPolicy}> 
      <Nav/>
    <div className={styles.priv482}>
    <h1 className={styles.privacyTitle}>
    Shipping Information
    </h1> 
    
    <div className={styles.privacytx}>
    <span><p>Thank you for shopping with us at LikeCandy.co.uk! We strive to deliver your order promptly and securely. Please find our shipping details below:</p></span>
    
<br/>
<h5>Processing Time:</h5>
<br/>
<span><p>
Orders are typically processed within 1-2 business days from the date of purchase. During peak periods, processing times may vary slightly.
</p></span>
<br/>
<h5>Shipping Time:</h5> 
<br/>
<span><p>Once processed, orders are shipped via standard shipping. Estimated delivery time is 7-10 business days within the United Kingdom. Please note that shipping times may vary based on your location and external factors like holidays or weather conditions.
</p></span>
<br/>
<h5>Shipping Rates:</h5>
<br/>
  <span><p>Shipping rates are calculated based on order weight and destination within the United Kingdom. You can view shipping costs during checkout before completing your purchase.</p>
<br/>
<h5>Customer Support:</h5>
<br/>
<span><p>
For any inquiries regarding your order or shipping, please contact our customer service team at info@likecandy.co.uk.

</p></span>
<br/>
 
<span><p>
Thank you for choosing LikeCandy.co.uk! We appreciate your business.
</p></span>
<br/>
 
        <br/> </span>


    </div>
  </div> 
<Footer/>
  </div>
  );
};

export default Shipping;

 