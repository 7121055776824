import React from 'react'; 
import styles from './styles.module.scss';  
import { FaFacebook } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa6";
import { FaTiktok } from "react-icons/fa";
import gateawayIcon from '../../../../images/gateawayIcon2.png';
import { Link } from 'react-router-dom';


export function Footer() { 

  return (
    <div className={styles.footerParent}>
    <div className={styles.footer}>
      <div className={styles.contain}>
      <div className={styles.col}>
        <h1>Navigation</h1>
        <ul>
          <li><a href="/">Home</a></li> 
          <li><a href="/products">E-shop</a></li>  
       
        </ul>
      </div>
      <div className={styles.col}>
      <h1>Support</h1>
        <ul>
          <li><a href="/shipping">Shipping</a></li>  
          
        </ul>
      </div>
      <div className={styles.col}>
        <h1>Company</h1>
        <ul>
        <li><a href="/aboutus">About Us</a></li>  
          <li><Link to="/">Contact Us</Link></li>  
        </ul>
      </div> 
      <div className={styles.col}>
        <h1>Social</h1>
        <ul className={styles.socialUn}>
          <li id={styles.socialLi}><a target="blank" href="https://www.facebook.com/profile.php?id=61562328273616"><FaFacebook/> </a></li>
          <li id={styles.socialLi}><a target="blank" href="https://www.instagram.com/likecandy.co.uk?igsh=dmJiZmRieXdtcDNv"><FaInstagram/> </a></li>
          <li id={styles.socialLi}><a target="blank" href="https://www.tiktok.com/@likecandy.co.uk?_t=8oZjlKca5sv&_r=1"><FaTiktok/> </a></li>
        </ul>
      </div>
    <div className={styles.clearfix}></div>
    </div>
    </div>
    <div className={styles.var975y4}> 
    <div className={styles.nin6500}>

      <div className={styles.uts4924}>
        <span>
          <p>© 2024 LikeCandy&nbsp; </p>
          <p> All rights reserved. |
            <a href='/privacypolicy' id={styles.r7et82}> Privacy policy</a> | <a href='/termsandconditions' id={styles.r7et82}>Terms & Conditions</a></p>
        </span>
      </div>

      <div className={styles.a11yjs}>
        <picture>
          <img src={gateawayIcon} alt="platobnekarty"/>
        </picture>
      </div>
    </div>
    </div>
    </div>
    
  );
}
