import React from 'react';
import styles from './styles.module.scss'; 

export const Hamburger = ({ clicked }) => {
  return (
    <div className={styles.bik6Ent}  onClick={clicked}>
    <div className={styles.Hamburger}>
      <span />
      <span />
      <span />
      <span />
    </div>
    </div>
  );
};


