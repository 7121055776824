import React, { useState, useEffect } from 'react';   
import styles from './styles.module.scss';     
import './style.css';


export const Subscribe = ({onclick}) => {  
 

 return (
   <section className={styles.penman1} onClick={onclick}>
    <div className={styles.lock7}>
      <span className={styles.glu8en}>
        Discount      </span> 
      </div>
        <div class="ch-newsletter">
  <div class="container">
    <div class="col-md-4 text-center">
      <image src="https://www.filepicker.io/api/file/PMPsvnqhTgCRWcRfXswL" class="newsletter-image"/>
    </div>
    <div class="newsletter-parent">
      <div class="newsletter-headline">
        Subscribe to our newsletter
      </div>
      <div class="newsletter-subtitle">
        To receive 10% of your first order
      </div>
      <form> 
        <input class="newsletter-input newsletter-input-email" type="email" placeholder="Enter your e-mail address"/>
        <input class="newsletter-submit2" type="submit" value="Submit"/>
      </form>
    </div>
  </div>
</div>
    </section>
  );
};
