import React from "react";
import styles from './../styles.module.scss';


const KrioryOptions = () => {

 
  
    return (
        <div>
        <h4 id={styles.diffuserPrice}>34.99£</h4>
        <h4 id={styles.diffuserDiscount}>41.99£</h4>
        <h4 id={styles.diffuserPercentage}>Save 16%</h4>
        <div className={styles.ho8216t}>
  <div className={styles.l5m227}>
    <span>
      <p>
      Unveil your inner seductress with our exclusive collection of erotic lingerie. Designed to ignite passion and allure, each piece is crafted for ultimate comfort and irresistible appeal. Slip into something unforgettable and let the night be yours.
      </p>
      <p id={styles.xy42p9}>
      

"Elegance is sexy, but lingerie is irresistible."
      </p>
    </span>
  </div>
</div>
     
   </div>
    
    );
  };

export default KrioryOptions;
 