import React, { useState, useEffect } from 'react';   
import styles from './styles.module.scss';     
import { BsArrowDownRight } from "react-icons/bs";
import pleasuremain from '../../../../images/crvena.jpg';
import redlololit from '../../../../images/imagez/redlolit.png';
import pur2tt from '../../../../images/imagez/pur2tt.png';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


export const Pleasure = ({onclick}) => {  
 

 return (
   <section className={styles.penman} onClick={onclick}>
       <div className={styles.selHD}>
        <div className={styles.emoJ}>
          <h2 id={styles.bec2}>
            EVERYTHING
          </h2>
          <h3 id={styles.bec3}>
            For your <i>Pleasure</i>
          </h3>
          <div className={styles.divider}>
            
          </div>
          <p id={styles.bec4}>
          Enjoy inclusive sizing, discreet packaging, and exceptional customer service for a seamless shopping experience!
          </p>
          <Link to="/products" className={styles.dth89}>
        Shop now
        <BsArrowDownRight id={styles.arrowright}/>
      </Link>
        </div>
        <div className={styles.momDe}>
           <img src={pleasuremain}/>
        </div>
        <div className={styles.ndle}>
        <div className={styles.oaH76}>
        <div className={styles.py3sl0}>
    <a href="https://www.kriory.sk/analysis/Lollipop" className={styles.xlde}>
         <img src={redlololit} id={styles.hlp}/>
    </a>
    <h3 className={styles.css14}>
        <a href="https://www.kriory.sk/analysis/Lollipop" className={styles.erb29}>
        Lollipop
        </a>
    </h3>
    <a href="https://www.kriory.sk/analysis/Lollipop" className={styles.ght25}>
    Like Candy
    </a>
    <div className={styles.ider0}>
        <h4 id={styles.price}>
        34,99&nbsp;£
        </h4>
    </div>
   </div>
   <div className={styles.py3sl0}>
    <a href="https://www.kriory.sk/analysis/Blissfull%20Bite" className={styles.xlde}>
    <img src={pur2tt} id={styles.hlp}/>
    </a>
    <h3 className={styles.css14}>
        <a href="https://www.kriory.sk/analysis/Blissfull%20Bite" className={styles.erb29}>
        Blissfull Bite
        </a>
    </h3>
    <a href="https://www.kriory.sk/analysis/Blissfull%20Bite" className={styles.ght25}>
    Like Candy
    </a>
    <div className={styles.ider0}>
        <h4 id={styles.price}>
        34,99&nbsp;£
        </h4>
    </div>
   </div>
   
        </div>
        <div className={styles.deo4}>
        <a href="/" className={styles.dth23}>
        Shop now
        <BsArrowDownRight id={styles.arrowright}/>
      </a>
      </div>
        <div className={styles.spoTm}><h3 id={styles.one2}>Premium Quality</h3><div id={styles.divider}></div><h2 id={styles.one141}>Our meticulous attention to detail means each piece is designed to fit perfectly and feel luxurious against your skin.</h2></div>
       </div>
        </div>
    </section>
  );
};
