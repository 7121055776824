import React, { useState } from 'react';  
import styles from './styles.module.scss';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { AiOutlineClose } from "react-icons/ai";
import chevrondown from '../../../../images/down-chevron-svgrepo-com.svg'; 
import customersupport from '../../../../images/customersupport.svg';
import mailbox from '../../../../images/mailbox.svg';
import { ClosingTag } from '../NavMobileOpenLinks/components/ClosingTag/ClosingTag';
import { NavBarLink } from '../NavMobileOpenLinks/components/NavLink/NavLink'; 
import { SearchBar } from '../SearchBar/SearchBar';
import { SearchBarMobile } from '../SearchBarMobile/SearchBarMobile';

export const Links = ({ opened, onClick }) => { 

  const [dropdown, setDropdown] = useState({
    necklaces: false,
    epoxy: false,
  });

  const necklaces = () => {
    setDropdown({necklaces: !dropdown.necklaces, epoxy: false})

  }

  const epoxy = () => {
    setDropdown({necklaces: false, epoxy: !dropdown.epoxy})

  }
 

    return (
        <div className={classNames(
          styles.ndleRst,
          opened && styles.opened
        )} opened={opened}>
          <div className={styles.closingNavbarMobile}  onClick={onClick}>
            
          
          </div>
        <div className={classNames(
          styles.Menu,
          opened && styles.opened
        )} opened={opened}>  
            <ul className={styles.openNavbarLinks}>
              <ClosingTag text={"Menu"} onClick={onClick} classname={styles.LinkDirection} /> 
              <li id={styles.summ98}>
              <SearchBarMobile/>
              </li>
              
              <NavBarLink link="/" alt="home" name="Home" classname={styles.LinkDirection}/>  
              <NavBarLink link="/aboutus" alt="home" name="About Us" classname={styles.LinkDirection}/>  
              
                  <div className={styles.irCp}></div>
                  <NavBarLink link="/products" alt="popularproducts" name="Popular Products" classname={styles.poPds}/>
                  <div className={styles.tsfYx}></div>
                  <div className={styles.pxthD}></div>
                  <div className={styles.f1rjtQs}>
    
  </div>
            </ul>
          </div>
        </div> 
    )
}
