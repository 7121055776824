// COFEE:: price_1PRiDNBaPAZzxpblG7Maf3CA   
// SUNGLASSES: price_1PRiF6BaPAZzxpbluGq6Y7Qp
// CAMERA: price_1PRiEdBaPAZzxpblZvoEAuRM
import purple from '../../../images/mirabelpurple.png';
import black from '../../../images/mirabelblack.png';
import imagez1 from '../../../images/imagez/sweetpleasure.png';
import imagez6 from '../../../images/imagez/sweetpleasure1.png';
import imagez2 from '../../../images/imagez/pur2tt.png';
import imagez3 from '../../../images/imagez/purt.png';
import imagez4 from '../../../images/imagez/lightbluet.png';
import imagez5 from '../../../images/imagez/pinktt.png';
import imagez7 from '../../../images/imagez/redlolit.png';
import bite1 from '../../../images/imagez/bite1.jpg';
import bite2 from '../../../images/imagez/bite2.jpg';
import bite3 from '../../../images/imagez/bite3.jpg';
import bite4 from '../../../images/imagez/bite4.jpg';
import bite5 from '../../../images/imagez/bite5.jpg';

import graini1 from '../../../images/imagez/sweetpleasure2.jpg';
import graini2 from '../../../images/imagez/sweetpleasure3.jpg';
import graini3 from '../../../images/imagez/sweetpleasure4.jpg';
import graini4 from '../../../images/imagez/sweetpleasure5.jpg';
import graini5 from '../../../images/imagez/sweetpleasure6.jpg';

import braini1 from '../../../images/imagez/braini1.jpg';
import braini2 from '../../../images/imagez/braini2.jpg';
import braini3 from '../../../images/imagez/braini3.jpg';

import fairytale from '../../../images/imagez/fairytale.jpg';
import fairytale2 from '../../../images/imagez/fairytale2.jpg'; 

import kraini1 from '../../../images/imagez/kraini1.jpg';
import kraini2 from '../../../images/imagez/kraini2.jpg';
import kraini3 from '../../../images/imagez/kraini3.jpg';

import maini1 from '../../../images/imagez/maini1.jpg';
import maini2 from '../../../images/imagez/maini5.jpg';
import maini3 from '../../../images/imagez/maini6.jpg';
import maini4 from '../../../images/imagez/maini7.jpg';
import maini5 from '../../../images/imagez/maini2.jpg';
import maini6 from '../../../images/imagez/maini4.jpg';
import maini7 from '../../../images/imagez/maini3.jpg';

import lolit1 from '../../../images/imagez/lolit1.jpg';
import lolit2 from '../../../images/imagez/lolit2.jpg';
import lolit3 from '../../../images/imagez/lolit3.jpg';
import lolit4 from '../../../images/imagez/lolit4.jpg';
import lolit5 from '../../../images/imagez/lolit5.jpg';
import lolit6 from '../../../images/imagez/lolit6.jpg';
import lolit7 from '../../../images/imagez/lolit7.jpg';
import lolit9 from '../../../images/imagez/lolit9.png';

const productsArray = [
    {
        id: "price_1PRid7BaPAZzxpblwUNhpPkl",
        size: "big",
        title: "Cotton Candy", 
        price: "34.99",
        imagethumbnail: [imagez1],
        image: [imagez1],
        parfumeWhiteThumbnail: [braini1, braini2, braini3], 
        options: ["S"],
        velkost: [],
           selectedOption: "",
           selectedVelicina: "",
           type: [{
            littleDragon: [maini1, maini2, maini3, maini4, maini5, maini6, maini7],
            littleElephant: [kraini1, kraini2, kraini3], 
          } ],
    },
    {
        id: "price_1PRid7BaPAZzxpblwUNhpPkl2", 
        title: "Cotton Candy",  
        options: ["M"],
        velkost: [],
        price: "34.99",
        imagethumbnail: [imagez1],
        image: [maini3],
        showProducts: 1,
           selectedOption: "",
           selectedVelicina: "",
           type: [{
            littleDragon: [],
            littleElephant: [], 
          } ],
    },
    {
        id: "price_1PRid7BaPAZzxpblwUNhpPkl3", 
        title: "Cotton Candy",  
        options: [],
        velkost: ["Black"],
        price: "34.99",
        image: [maini2],
        showProducts: 1,
           selectedOption: "",
           selectedVelicina: "",
           type: [{
            littleDragon: [],
            littleElephant: [], 
          } ],
    },
    {
        id: "price_1PRid7BaPAZzxpblwUNhpPkl4", 
        title: "Cotton Candy", 
        options: [],
        velkost: ["White"],
        price: 34.99,
        image: [maini2],
        showProducts: 1,
           selectedOption: "",
           selectedVelicina: "",
           type: [{
            littleDragon: [],
            littleElephant: [], 
          } ],
    },
    {
      id: "price_1PRid7BaPAZzxpblwUNhpPkl5", 
      title: "Cotton Candy", 
      options: [],
      velkost: ["Pink"],
      price: 34.99,
      image: [maini1],
      showProducts: 1,
         selectedOption: "",
         selectedVelicina: "",
         type: [{
          littleDragon: [],
          littleElephant: [], 
        } ],
  },
  {
    id: "price_1PRid7BaPAZzxpblwUNhpPkl6", 
    title: "Cotton Candy", 
    options: [],
    velkost: ["Purple"],
    price: 34.99,
    image: [maini1],
    showProducts: 1,
       selectedOption: "",
       selectedVelicina: "",
       type: [{
        littleDragon: [],
        littleElephant: [], 
      } ],
},
{
  id: "price_1PRid7BaPAZzxpblwUNhpPkl7", 
  title: "Cotton Candy", 
  options: [],
  velkost: [],
  price: 34.99,
  image: [maini6],
  showProducts: 1,
     selectedOption: "",
     selectedVelicina: "",
     type: [{
      littleDragon: [],
      littleElephant: [], 
    } ],
},
{
  id: "price_1PRid7BaPAZzxpblwUNhpPkl8", 
  title: "Cotton Candy", 
  options: [],
  velkost: [],
  price: 34.99,
  image: [maini6],
  showProducts: 1,
     selectedOption: "",
     selectedVelicina: "",
     type: [{
      littleDragon: [],
      littleElephant: [], 
    } ],
},
{
  id: "price_1PRid7BaPAZzxpblwUNhpPkl9", 
  title: "Cotton Candy", 
  options: [],
  velkost: [],
  price: 34.99,
  image: [maini4],
  showProducts: 1,
     selectedOption: "",
     selectedVelicina: "",
     type: [{
      littleDragon: [],
      littleElephant: [], 
    } ],
},
{
  id: "price_1PRid7BaPAZzxpblwUNhpPkl10", 
  title: "Cotton Candy", 
  options: [],
  velkost: [],
  price: 34.99,
  image: [maini4],
  showProducts: 1,
     selectedOption: "",
     selectedVelicina: "",
     type: [{
      littleDragon: [],
      littleElephant: [], 
    } ],
},
{
  id: "price_1PRid7BaPAZzxpblwUNhpPkl7", 
  title: "Cotton Candy", 
  options: [],
  velkost: ["Blue"],
  price: 34.99,
  showProducts: 1,
     selectedOption: "",
     selectedVelicina: "",
     type: [{
      littleDragon: [],
      littleElephant: [], 
    } ],
},

    {
        id: "price_1PRideBaPAZzxpblcZPLGcPz",
        size: "big",
        title: "Blissfull Bite",
        price: 34.99,
        image: [imagez2],
        imagethumbnail: [imagez2],
        parfumeWhiteThumbnail: [bite2], 
        options: ["S"],
        velkost: ["Default"],
           selectedOption: "",
           selectedVelicina: "",
           type: [{
            littleDragon: [bite2,bite5, bite3, bite4, bite1],
            littleElephant: [kraini1, kraini2, kraini3], 
          } ],
    },
    {
      id: "price_1PRideBaPAZzxpblcZPLGcPz2",
      size: "big",
      title: "Blissfull Bite",
      price: 34.99,
      image: [imagez2],
      parfumeWhiteThumbnail: [bite2], 
      options: ["M"],
      velkost: [],
      showProducts: 1,
         selectedOption: "",
         selectedVelicina: "",
         type: [{
          littleDragon: [],
          littleElephant: [], 
        } ],
  },
    {
        id: "price_1PRidoBaPAZzxpblfYPzLGr4",
        size: "big",
        title: "Fairytale Flavour",
        price: 34.99,
        image: [imagez4],
        imagethumbnail: [imagez4],
        parfumeWhiteThumbnail: [fairytale, fairytale2],
        options: ["S"],
        velkost: ["Default"],
           selectedOption: "",
           selectedVelicina: "",
           type: [{
            littleDragon: [fairytale, fairytale2],
            littleElephant: [kraini1, kraini2, kraini3], 
          } ],
    },
    {
      id: "price_1PRidoBaPAZzxpblfYPzLGr34.99",
      size: "big",
      title: "Fairytale Flavour",
      price: 34.99,
      image: [imagez4],
      imagethumbnail: [imagez4],
      parfumeWhiteThumbnail: [],
      options: ["M"],
      velkost: [],
      showProducts: 1,
         selectedOption: "",
         selectedVelicina: "",
         type: [{
          littleDragon: [],
          littleElephant: [], 
        } ],
  },
    {
        id: "price_1PRid7BaPAZzxpblwUNhpPkl123",
        size: "big",
        title: "Sweet Pleasure",
        price:34.99,
        image: [imagez6],
        imagethumbnail: [imagez6], 
        parfumeWhiteThumbnail: [graini1, graini2, graini3, graini4, graini5],
        options: ["S"],
        velkost: ["Default"],
           selectedOption: "",
           selectedVelicina: "",
           type: [{
            littleDragon: [graini1, graini2, graini3, graini4, graini5],
            littleElephant: [], 
          } ],
    },
    {
      id: "price_1PRid7BaPAZzxpblwUNhpPklx222",
      size: "big",
      title: "Sweet Pleasure",
      price:34.99,
      image: [imagez6],
      imagethumbnail: [imagez6], 
      parfumeWhiteThumbnail: [],
      options: ["M"],
      showProducts: 1,
      velkost: [],
         selectedOption: "",
         selectedVelicina: "",
         type: [{
          littleDragon: [],
          littleElephant: [], 
        } ],
  },

    {
        id: "price_1PRid7BaPAZzxpblwUNhpPklhhh",
        size: "big",
        title: "Lollipop",
        price: 34.99,
        image: [lolit9],  
        imagethumbnail: [imagez7],
        parfumeWhiteThumbnail: [lolit2],
        perfumeBlackThumbnail: [],
        options: ["S"],
        velkost: [],
           selectedOption: "",
           selectedVelicina: "",
           type: [{
            littleDragon: [lolit2, lolit6, lolit3, lolit4,lolit5,lolit1,lolit7],
            littleElephant: [], 
          } ],
    },
    {
      id: "price_1PRid7BaPAZzxpblwUNhpPklhhh2",
      size: "big",
      title: "Lollipop",
      price: 34.99,
      image: [lolit9],  
      imagethumbnail: [imagez7],
      parfumeWhiteThumbnail: [lolit7],
      perfumeBlackThumbnail: [],
      showProducts: 1,
      options: ["M"],
      velkost: [],
         selectedOption: "",
         selectedVelicina: "",
         type: [{
          littleDragon: [],
          littleElephant: [], 
        } ],
  },
  {
    id: "price_1PRid7BaPAZzxpblwUNhpPklhhh3",
    size: "big",
    title: "Lollipop",
    price: 34.99,
    image: [imagez7],  
    imagethumbnail: [imagez7],
    parfumeWhiteThumbnail: [lolit7],
    perfumeBlackThumbnail: [],
    showProducts: 1,
    options: [],
    velkost: ["Black"],
       selectedOption: "",
       selectedVelicina: "",
       type: [{
        littleDragon: [],
        littleElephant: [], 
      } ],
},
{
  id: "price_1PRid7BaPAZzxpblwUNhpPklhhh4",
  size: "big",
  title: "Lollipop",
  price: 34.99,
  image: [imagez7],  
  imagethumbnail: [imagez7],
  parfumeWhiteThumbnail: [lolit7],
  perfumeBlackThumbnail: [],
  showProducts: 1,
  options: [],
  velkost: ["Red"],
     selectedOption: "",
     selectedVelicina: "",
     type: [{
      littleDragon: [],
      littleElephant: [], 
    } ],
},
];

function getProductData(id) {
    let productData = productsArray.find(product => product.id === id);

    if (productData == undefined) {
        console.log("Product data does not exist for ID: " + id);
        return undefined;
    }

    return productData;
}

export { productsArray, getProductData };