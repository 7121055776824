import asakukiWhite from '../../images/logo1.png';
import lance from '../../images/necklace.png';
import svadba from '../../images/wedding.svg';
import mirabell from '../../images/mirabelpurple.png';
import imagez1 from '../../images/imagez/pur2tt.png'; 

export const trendingdata = [
  {  
    title: 'Blissfull Bite',
    parfumeWhiteThumbnail: [imagez1], 
  },   
//   { 
//     title: 'Svadba',
//     parfumeWhiteThumbnail: [asakukiWhite], 
//     image: [svadba],
//     text: 'Wedding invitation'
//   },
//   { 
//     title: 'Grebalica',
//     parfumeWhiteThumbnail: [asakukiWhite], 
    
//   },
];
