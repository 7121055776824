import React from 'react'; 
import { Switch, Route, BrowserRouter } from 'react-router-dom'    
import { HomePage } from './pages/HomePagec/HomePage';
import { ProductsPage } from './pages/AllProducts/ProductsPage';
import CartProvider from './pages/store/components/CardContext';
import { Analysis } from './components/Analysis/Analysis';
import AboutUs from './pages/AboutUs/AboutUs';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse/TermsOfUse';
import Shipping from './pages/Shipping/Shipping';

export const App = () => (

  <>
  <CartProvider>
  <BrowserRouter>  
    <Switch>
    <Route
        exact path="/"
        component={HomePage}
      /> 
        <Route
        path="/products"
        component={ProductsPage}
      />

<Route
        path="/aboutus"
        component={AboutUs}
      />
      <Route
        path="/privacypolicy"
        component={PrivacyPolicy}
      />
        <Route
        path="/shipping"
        component={Shipping}
      />
      <Route
        path="/termsandconditions"
        component={TermsOfUse}
      />

      <Route
        path="/analysis/:title"
        component={Analysis}
      /> 
      
    </Switch> 
  </BrowserRouter>
  </CartProvider>
</>

);

