import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { productsArray } from '../../pages/store/components/products'; 
import styles from './styles.module.scss'; 
import { Link } from 'react-router-dom'; 
import { Nav } from '../../components/Nav/Nav';
import KrioryOptions from './KrioryOptions/krioryOptions';
import styled from 'styled-components'; 
import { KrioryRights } from './KrioryRights/KrioryRights';
import './style.css';
import { Footer } from '../../pages/HomePagec/components/Footer/Footer';
import Reviews from './Reviews/Reviews';
import KriorySpecs from './KriorySpecs/KriorySpecs';
import { CartContext } from '../../pages/store/components/CardContext';

export function Analysis() {
  const { title } = useParams();
  const profile = productsArray.filter(profile => profile.title === title);
  console.log(profile[0])
  const [mainImage, setMainImage] = useState(profile[0].parfumeWhiteThumbnail[0])  
   const cart = useContext(CartContext);
   const [addImages, setAddImages] = useState(profile[0]);
   const [color, setColor] = useState('first');

  const sizeS = {
    options: [
      {value: "status1", label: "status 1", secretValue: "foo"},
      {value: "status2", label: "status 2", secretValue: "foo"},
      {value: "status3", label: "status 3", secretValue: "foo"},
    ],
     selectedOption: ""
  };

   
  
  const MeniBlue = styled.div`
  cursor: pointer;
  color: #D5A676;
  width: 90px;
  height: 90px;
  display: flex;
  overflow: hidden;
  position: relative;
  flex-basis: auto;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
    box-shadow: ${color === "first" ? '0px 0px 0px 2px rgba(213,166,118,1)' : '0px'};
    -webkit-box-shadow: ${color === "first" ? '0px 0px 0px 2px rgba(213,166,118,1)' : '0px'};
    -moz-box-shadow: ${color === "first" ? '0px 0px 0px 2px rgba(213,166,118,1)' : '0px'};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  &:hover { 
  -webkit-box-shadow: 0px 0px 0px 2px rgba(213,166,118,1);
-moz-box-shadow: 0px 0px 0px 2px rgba(213,166,118,1);
box-shadow: 0px 0px 0px 2px rgba(213,166,118,1);
  }

  @media (max-width: 550px) {
    width: 69.5px;
    height: 69.5px;
  }
 
  `;
 
  const MeniWhite = styled.div`
  margin-left: 10px;
  cursor: pointer;
  color: #D5A676;
  width: 90px;
  height: 90px;
  display: ${profile[0].second ? 'flex' : 'none'};
  overflow: hidden;
  position: relative;
  flex-basis: auto;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
    box-shadow: ${color === "second" ? '0px 0px 0px 2px rgba(213,166,118,1)' : '0px'};
    -webkit-box-shadow: ${color === "second" ? '0px 0px 0px 2px rgba(213,166,118,1)' : '0px'};
    -moz-box-shadow: ${color === "second" ? '0px 0px 0px 2px rgba(213,166,118,1)' : '0px'};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  &:hover { 
  -webkit-box-shadow: 0px 0px 0px 2px rgba(213,166,118,1);
-moz-box-shadow: 0px 0px 0px 2px rgba(213,166,118,1);
box-shadow: 0px 0px 0px 2px rgba(213,166,118,1);
  }

  @media (max-width: 550px) {
    width: 69.5px;
    height: 69.5px;
  }
 
  `;



  const [state, setState] = useState({
    isKrioryOpen: false,
    isReturnsOpen: false,
    isSpecificationsOpen: false,
    isInTheBoxOpen: false,
  });
  
  const OpenWhatIsKriory = () => {
    setState({ isKrioryOpen: !state.isKrioryOpen });
    
  };

   
  
  const OpenReturns = () => {
    setState({ isReturnsOpen: !state.isReturnsOpen });
    
  };

  const OpenSpecifications = () => {
    setState({ isSpecificationsOpen: !state.isSpecificationsOpen });
    
  };

  const OpenInTheBox = () => {
    setState({ isInTheBoxOpen: !state.isInTheBoxOpen });
    
  };  
 const parfumeMaterial = profile[0].parfumeMaterial; 
 
 const [showSearch, setShowSearch] = useState(false);
 const search = () => {
   setShowSearch(showSearch => !showSearch);
   
 };  

const firstItem = profile.entries().next().value;
const secondItem = firstItem.entries().next().value;
 
const array = firstItem;

 

const index = array[0];
if (index > -1) { // only splice array when item is found
  array.splice(index, 1); // 2nd parameter means remove one item only
}
 
  return (
    <div className={styles.analyses}> 
     <Nav state={showSearch} searchFun={search} showSearchBar={showSearch}/>
    
      {array.map(pr => (
        
          <section>
              <div className={styles.productFlex}>
      <ul className={styles.med97}>
        <li ><span id={styles.hpG}><a href="/">Home Page</a></span></li>
        
        <li><span  id={styles.ppG}>{pr.title}</span></li>
      </ul>
    </div>  

    <section className={styles.s4gj9k} onClick={() => setShowSearch(false)}>
               
               <div class={styles.containers}>
               <div class={styles.lefts}>
                 <div className={styles.k63k7s0}>
                 <div class={styles.mainImage}>
                     <picture>
                     <img src={mainImage} class="slide"/>
                       </picture>
                   </div>
                 
                   <div class={styles.option}>
                     
                      
                           <div className={styles.option}> 
                           {
                            profile.map((curElm) => {
                              if(color === "first"){
                               return (
                               
                                <div className={styles.optionElm}>{
                                curElm.type.map((og) => og.littleDragon.map(now => {

                                  return (
                                    <img src={now} alt=''
                                    onClick={() => setMainImage(now)}/>
                                  )
                                }) )
                               }</div> 
                                
                            
                           
                             )  } else if(color === "second") {
                              return (
                               
                                <div>{
                                  curElm.type.map((og) => og.littleElephant.map(now => {
  
                                    return (
                                      <img src={now} alt=''
                                      onClick={() => setMainImage(now)}/>
                                    )
                                  }) )
                                 }</div> 
                                 
                             
                            
                              )
                             }})
                        }</div>
                    
                      </div>
                 </div>
                
                      </div>
                
                 <div class={styles.rights}>
                   <div className={styles.ra742gn}>
                   <span><svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 576 512"><path fill="#ffa41c" d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg>
         <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 576 512"><path fill="#ffa41c" d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg>
         <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 576 512"><path fill="#ffa41c" d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg>
         <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 576 512"><path fill="#ffa41c" d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg>
         <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 576 512"><path fill="#ffa41c" d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg></span>
         <span><p> (98)</p> </span> 
                   </div>
                  
                           <div className={styles.lck462o}>
                             <span>
                               <p>{pr.title}</p>
                             </span> 
                           </div> 
                     
                      
                         
                      
                   
                  
                           <KrioryOptions/>
                     
                     
                  <div className={styles.tp90lk4}> 
                  <div>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"> <path fill="#01A971" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                   </div>
                  <span>
                   <p>
                   Available
                   </p>
                  </span>
                  
                  </div>
                   
                    
                     <div className={styles.colorsWrapper}> 
<div className={styles.firstColor}  >
  <div onClick={cart.setRed}>
<MeniBlue >
  
<div className={styles.colorChoicesImage} onClick={() => setColor("first")}><img id={styles.imgusID} src={pr.imagethumbnail} onClick={() => setMainImage(pr.image)}/></div> 


</MeniBlue></div>
<div onClick={cart.setBlue}>
<MeniWhite className={pr.second ? styles.lol : styles.colorChs}>
  
<div className={pr.second ? styles.colorChoicesImage : styles.colorChs} onClick={() => setColor("second")}><img id={styles.imgusID} src={pr.second} onClick={() => setMainImage(pr.second)}/></div> 


</MeniWhite></div>
</div></div>

             <KrioryRights clicked={OpenWhatIsKriory} opened={OpenInTheBox} specs={OpenSpecifications} returned={OpenReturns} stateKrioryOpen={state.isKrioryOpen} stateReturnsOpen={state.isReturnsOpen} stateSpecsOpen={state.isSpecificationsOpen} stateInTheBoxOpen={state.isInTheBoxOpen} />
               
            
           
             
            
                 </div>
               </div>
               <div className={styles.s2_divider_Wrapper}><div className={styles.s2_divider}></div></div>
          
           
             </section> 
             <KriorySpecs onclick={() => setShowSearch(false)}/>
    <Reviews onclick={() => setShowSearch(false)}/>
  
      <Footer/>
        </section>
        
      ))}
     
    </div>
  );
}

