import React, { useState, useRef } from "react";
import './styles.css';
import styles from './styles.module.scss'; 
import { FaStar } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa";
import styled from 'styled-components';
import emailjs from '@emailjs/browser';
import { FaFacebook } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaTiktok } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6"; 
import { dataReviews } from "./dataReviews";
import Pagination from "./pagination/pagination";
import ReviewTemplate from "./reviewTemplate/ReviewTemplate";
 
 
const Reviews = ({onclick}) => {
    
//     const [filteredItems, setFilteredItems] = useState(dataReviews);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [postsPerPage, setPostsPerPage] = useState(4); 
//     const indexOfLastPost = currentPage * postsPerPage;
//     const indexOfFirstPost = indexOfLastPost - postsPerPage;
//     const currentPosts = filteredItems.slice(indexOfFirstPost, indexOfLastPost);
//     const paginate = pageNumber => setCurrentPage(pageNumber);

// const handlePagination = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

    const [review, setReview] = useState({
        isWriteAreviewOpen: false,
      });
    
    const OpenWriteAreview = () => {
        setReview({ isWriteAreviewOpen: !review.isWriteAreviewOpen });
        
      };

    const form = useRef();
    const [success, setSuccess] = useState(false);
  
    
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm('service_vkt5vgu', 'template_x74mhlh', form.current, {
          publicKey: 'TwWcyk6P3YkW0nCHJ',
        })
        .then(
          () => {
            setTimeout(() => setSuccess(true), 1000)
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
    };
  
    const WriteAreview = styled.div`  
      position: relative;
      width: 100%;
      padding: 1em;
      margin: 0 auto;
      border: 1px solid #D5A676;
      background-color: transparent;
      border-radius: 10px;
    `

    const Review238 = styled.div`
    display: ${review.isWriteAreviewOpen ? 'block' : 'none'};
    max-width: 419px;
    margin: 1em auto 0 auto;
    padding-left: 1em;
    padding-right: 1em;

    `
console.log(review.isWriteAreviewOpen)
  return ( 
    <section onClick={onclick}>
    <div className={styles.wrapList} id="review">
      <div className={styles.expWid}>
        <div className={styles.summary}>
          <div className={styles.summaryContent}>
             <div className={styles.expandedSummary}>
             <FaStar/>
             <span>4.96</span>
             </div>
             <div className={styles.summaryText}>
              <span>98 Reviews</span>
             </div>
          </div>
          <div className={styles.reviewsDist}>
            <div className={styles.progressSection}>
                 <table>
                    <tbody>
                        <tr>
                            <td>
                                <div className={styles.reviewNum}>
                                     <ul>
                                        <li><FaStar/></li>
                                        <li><FaStar/></li>
                                        <li><FaStar/></li>
                                        <li><FaStar/></li>
                                        <li><FaStar/></li>
                                     </ul>
                                </div>
                            </td>
                            <td id={styles.dobri2}>
                                <div className={styles.looxProgress}>
                                    <div className={styles.looxValue}>

                                    </div>
                                </div>
                                </td>
                                <td id={styles.dobri6}>
                                (96)
                                </td>
                        </tr>
                        <tr>
                            <td>
                                <div className={styles.reviewNum}>
                                     <ul>
                                        <li><FaStar/></li>
                                        <li><FaStar/></li>
                                        <li><FaStar/></li>
                                        <li><FaStar/></li>
                                        <li><FaRegStar/></li>
                                     </ul>
                                </div>
                            </td>
                            <td id={styles.dobri2}>
                                <div className={styles.looxProgress}>
                                    <div className={styles.looxValue2}>

                                    </div>
                                </div>
                                </td>
                                <td id={styles.dobri6}>
                                (2)
                                </td>
                        </tr>
                        <tr>
                            <td>
                                <div className={styles.reviewNum}>
                                     <ul>
                                        <li><FaStar/></li>
                                        <li><FaStar/></li>
                                        <li><FaStar/></li>
                                        <li><FaRegStar/></li>
                                        <li><FaRegStar/></li>
                                     </ul>
                                </div>
                            </td>
                            <td id={styles.dobri2}>
                                <div className={styles.looxProgress}>
                                    <div className={styles.looxValue3}>

                                    </div>
                                </div>
                                </td>
                                <td id={styles.dobri6}>
                                (0)
                                </td>
                        </tr>
                        <tr>
                            <td>
                                <div className={styles.reviewNum}>
                                     <ul>
                                        <li><FaStar/></li>
                                        <li><FaStar/></li>
                                        <li><FaRegStar/></li>
                                        <li><FaRegStar/></li>
                                        <li><FaRegStar/></li>
                                     </ul>
                                </div>
                            </td>
                            <td id={styles.dobri2}>
                                <div className={styles.looxProgress}>
                                    <div className={styles.looxValue3}>

                                    </div>
                                </div>
                                </td>
                                <td id={styles.dobri6}>
                                (0)
                                </td>
                        </tr>
                        <tr>
                            <td>
                                <div className={styles.reviewNum}>
                                     <ul>
                                        <li><FaStar/></li>
                                        <li><FaRegStar/></li>
                                        <li><FaRegStar/></li>
                                        <li><FaRegStar/></li>
                                        <li><FaRegStar/></li>
                                     </ul>
                                </div>
                            </td>
                            <td id={styles.dobri2}>
                                <div className={styles.looxProgress}>
                                    <div className={styles.looxValue3}>

                                    </div>
                                </div>
                                </td>
                                <td id={styles.dobri6}>
                                (0)
                                </td>
                        </tr>
                    </tbody>
                 </table>
            </div>
          </div>
        </div>
        <div className={styles.widgeHeader} onClick={OpenWriteAreview}>
             <button>Write a review</button>
        </div>
       
      </div>
      <Review238>
      <WriteAreview>
           <div className={styles.exp2553}><span>Write a review</span></div>
           <div className={styles.rat962}><span>Rating:</span></div>
           <div class="rate">
    <input type="radio" id="star5" name="rate" value="5" />
    <label for="star5" title="text">5 stars</label>
    <input type="radio" id="star4" name="rate" value="4" />
    <label for="star4" title="text">4 stars</label>
    <input type="radio" id="star3" name="rate" value="3" />
    <label for="star3" title="text">3 stars</label>
    <input type="radio" id="star2" name="rate" value="2" />
    <label for="star2" title="text">2 stars</label>
    <input type="radio" id="star1" name="rate" value="1" />
    <label for="star1" title="text">1 star</label>
  </div>
  
  <form ref={form} onSubmit={sendEmail} class="formcjs">
          
          <div class="input-container">
            <input type="text" name="from_name" class="input" required/>
            <label for="">Name:</label>
            <span>Name</span>
          </div>
          <div class="input-container">
            <input type="email" name="from_email" class="input" required/>
            <label for="">Email:</label>
            <span>Email</span>
          </div> 
          <div class="input-container textarea" id="textarea">
            <textarea name="message" class="input" required></textarea>
            <label for="">Message:</label>
            <span>Review</span>
          </div>
          <div className={styles.pic851}>
            <span>We would like to see a picture</span>
            <input type="file"/>
        </div>
          <div className={styles.eiv28s}>
          <div className={styles.d9df021a}>
          <button type="submit" value="Send" className={success ? styles.c212ptpz : styles.c212ptp}>
         <div className={styles.r17s8b7}>
            
          <span>
            <p>{success ? 'Sent' : 'Send'}</p>
          </span>
         </div>
        </button>
        </div>
        <div className={styles.d9df021a}>
          <div className={styles.c212ptph}>
         <div className={styles.r17s8b7h} onClick={OpenWriteAreview}>
            
          <span>
            <p>Close</p>
          </span>
         </div>
        </div>
        </div>
        </div>
       
        <div className={success ? styles.succ95 : styles.succ95s}><span>
        Thank you for your rating. Our team will analyze it and publish it.</span></div>
        </form>
            </WriteAreview>
            </Review238>
          
  </div>
  <ReviewTemplate/>  
 
 
</section>
  );
};

export default Reviews;